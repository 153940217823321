
export const DeleteSatelliteSvg = ({forAdd}) => {

    return (
        <>
        { forAdd ? 
            <div style={{position:'relative', marginTop:'20px'}} className="">
            <svg width="64" height="80" viewBox="0 0 64 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M42.0579 52.9766C40.1042 52.9714 38.1552 52.7833 36.2355 52.4147C36.0153 52.3583 35.8135 52.2444 35.6501 52.0842L12.6208 28.6837C12.4797 28.5234 12.3792 28.3305 12.328 28.1218C11.3767 23.173 11.6355 18.0626 13.0817 13.2392C14.5279 8.41579 17.1173 4.02685 20.6225 0.457666C20.8402 0.26908 21.117 0.165497 21.4031 0.165497C21.6892 0.165497 21.966 0.26908 22.1837 0.457666L63.4283 42.4001C63.5296 42.5016 63.61 42.6225 63.6649 42.7558C63.7198 42.8892 63.7481 43.0323 63.7481 43.1768C63.7481 43.3213 63.7198 43.4644 63.6649 43.5978C63.61 43.7311 63.5296 43.852 63.4283 43.9535C60.6275 46.8149 57.2976 49.0852 53.6299 50.6337C49.9623 52.1823 46.0295 52.9785 42.0579 52.9766ZM36.9836 50.2664C41.2683 51.0659 45.6773 50.84 49.8611 49.6065C54.0448 48.373 57.8882 46.1659 61.0863 43.1603L21.4356 2.83737C18.4671 6.08763 16.2862 9.9971 15.0665 14.2546C13.8468 18.5122 13.6219 23.0002 14.4097 27.3616L36.9836 50.2664Z" fill="url(#paint0_linear)"/>
                <path d="M22.7039 59.2231C22.5586 59.2246 22.4144 59.1961 22.2802 59.1393C22.146 59.0825 22.0246 58.9985 21.9233 58.8926L5.88738 42.6973C5.68081 42.4867 5.56482 42.2015 5.56482 41.9041C5.56482 41.6067 5.68081 41.3215 5.88738 41.1109L16.1335 30.6996C16.3408 30.4897 16.6215 30.3719 16.9141 30.3719C17.2067 30.3719 17.4875 30.4897 17.6948 30.6996L33.6981 46.994C33.8045 47.096 33.8892 47.2189 33.9471 47.3554C34.0049 47.4918 34.0348 47.6387 34.0348 47.7873C34.0348 47.9358 34.0049 48.0828 33.9471 48.2192C33.8892 48.3556 33.8045 48.4786 33.6981 48.5805L23.4846 58.9587C23.2675 59.1456 22.9879 59.2403 22.7039 59.2231ZM8.22934 41.838L22.7039 56.5459L31.3887 47.7212L16.9141 33.0132L8.22934 41.838Z" fill="url(#paint1_linear)"/>
                <path d="M10.4088 63.4867C8.6211 63.5082 6.86755 62.9889 5.37121 61.9947C3.87488 61.0006 2.70339 59.5766 2.00588 57.9039C1.30837 56.2313 1.11638 54.3856 1.45426 52.6017C1.79215 50.8178 2.64465 49.1764 3.90333 47.8863L8.19694 43.5235C8.29681 43.4206 8.41578 43.3388 8.547 43.2831C8.67823 43.2273 8.81909 43.1985 8.96134 43.1985C9.10358 43.1985 9.2444 43.2273 9.37562 43.2831C9.50684 43.3388 9.62586 43.4206 9.72573 43.5235L20.915 54.8932C21.1216 55.1039 21.2377 55.3891 21.2377 55.6865C21.2377 55.9839 21.1216 56.2691 20.915 56.4797L16.7191 60.8095C15.8947 61.6588 14.9121 62.333 13.8288 62.7926C12.7454 63.2522 11.5828 63.4882 10.4088 63.4867ZM9.07514 46.0024L5.59476 49.3075C4.30075 50.6224 3.57377 52.4058 3.57377 54.2653C3.57377 56.1248 4.30075 57.9081 5.59476 59.223C6.88877 60.5379 8.64383 61.2766 10.4738 61.2766C12.3038 61.2766 14.0589 60.5379 15.3529 59.223L18.8659 55.6534L9.07514 46.0024Z" fill="url(#paint2_linear)"/>
                <path d="M54.6136 50.0678C54.4677 50.0734 54.3224 50.0468 54.1876 49.9897C54.0528 49.9327 53.9318 49.8465 53.833 49.7373L14.8004 10.0754C14.6441 9.90543 14.5418 9.69148 14.507 9.46152C14.4722 9.23157 14.5064 8.9963 14.6052 8.78642C16.0888 5.64182 18.0787 2.77093 20.4927 0.292157C20.7104 0.103571 20.9871 0 21.2733 0C21.5594 0 21.8362 0.103571 22.0539 0.292157L63.2985 42.2346C63.3997 42.3361 63.4801 42.457 63.535 42.5903C63.59 42.7237 63.6182 42.8668 63.6182 43.0113C63.6182 43.1558 63.59 43.2989 63.535 43.4323C63.4801 43.5656 63.3997 43.6865 63.2985 43.788C60.875 46.269 58.0466 48.3043 54.9389 49.8034C54.8492 49.9129 54.7385 50.0028 54.6136 50.0678ZM17.0448 9.21608L54.8088 47.5889C57.0949 46.418 59.208 44.9272 61.0866 43.16L21.4359 2.83713C19.6909 4.74849 18.2133 6.89498 17.0448 9.21608Z" fill="url(#paint3_linear)"/>
                <path d="M42.0254 23.329C41.8846 23.332 41.7448 23.3041 41.6157 23.247C41.4866 23.19 41.3711 23.1052 41.2773 22.9985C41.1709 22.8966 41.0862 22.7736 41.0284 22.6372C40.9705 22.5008 40.9406 22.3538 40.9406 22.2053C40.9406 22.0567 40.9705 21.9097 41.0284 21.7733C41.0862 21.6369 41.1709 21.514 41.2773 21.412L48.8235 13.7441C48.9234 13.6411 49.0424 13.5594 49.1736 13.5036C49.3049 13.4478 49.4457 13.4191 49.5879 13.4191C49.7302 13.4191 49.871 13.4478 50.0023 13.5036C50.1335 13.5594 50.2525 13.6411 50.3523 13.7441C50.5589 13.9547 50.6749 14.2399 50.6749 14.5373C50.6749 14.8347 50.5589 15.1199 50.3523 15.3305L42.741 22.8663C42.6619 22.9916 42.557 23.0979 42.4334 23.1778C42.3098 23.2577 42.1706 23.3093 42.0254 23.329Z" fill="url(#paint4_linear)"/>
                <path d="M52.7589 16.9831C51.8373 16.9845 50.9297 16.7544 50.1167 16.3135C49.3037 15.8725 48.6104 15.2343 48.0986 14.4556C47.5867 13.6768 47.2721 12.7817 47.1827 11.8496C47.0934 10.9176 47.232 9.97756 47.5862 9.11306C47.9405 8.24856 48.4994 7.48637 49.2134 6.89422C49.9273 6.30208 50.7742 5.89831 51.6787 5.71881C52.5832 5.53932 53.5173 5.58964 54.3981 5.86532C55.2788 6.141 56.0789 6.6335 56.7272 7.29903C57.5035 8.09589 58.0305 9.10818 58.242 10.2086C58.4535 11.309 58.34 12.4484 57.9159 13.4835C57.4917 14.5186 56.7759 15.4031 55.8583 16.0258C54.9407 16.6485 53.8623 16.9816 52.7589 16.9831ZM52.7589 7.86091C52.0528 7.77127 51.3372 7.91942 50.7218 8.28264C50.1065 8.64585 49.6254 9.2041 49.3522 9.87179C49.079 10.5395 49.0289 11.2798 49.2094 11.9792C49.39 12.6787 49.7913 13.2986 50.3519 13.7441C50.6568 14.0849 51.0284 14.3572 51.4429 14.5436C51.8574 14.7299 52.3057 14.8262 52.7589 14.8262C53.2122 14.8262 53.6604 14.7299 54.0749 14.5436C54.4894 14.3572 54.8611 14.0849 55.1659 13.7441C55.4961 13.4346 55.7596 13.059 55.9398 12.6409C56.12 12.2228 56.2131 11.7712 56.2131 11.3148C56.2131 10.8584 56.12 10.4068 55.9398 9.98875C55.7596 9.57065 55.4961 9.19501 55.1659 8.88551C54.8561 8.55512 54.4822 8.29351 54.068 8.11717C53.6537 7.94083 53.208 7.85357 52.7589 7.86091Z" fill="url(#paint5_linear)"/>
                <path d="M29.0789 68.7751H22.5734C22.2857 68.7752 22.0092 68.6613 21.8027 68.4577C21.5962 68.254 21.476 67.9766 21.4675 67.6844V58.1655C21.4717 57.8685 21.5881 57.5844 21.7928 57.3723L32.0389 46.961C32.1387 46.8581 32.2577 46.7764 32.3889 46.7206C32.5202 46.6648 32.661 46.636 32.8033 46.636C32.9455 46.636 33.0863 46.6648 33.2175 46.7206C33.3488 46.7764 33.4677 46.8581 33.5676 46.961L37.0805 50.5306C37.2409 50.6838 37.3492 50.8847 37.3902 51.1044C37.4311 51.3241 37.4024 51.5514 37.3083 51.7535L29.9571 68.2793C29.8638 68.4305 29.7343 68.5553 29.5808 68.642C29.4272 68.7286 29.2545 68.7744 29.0789 68.7751ZM23.7769 66.5606H28.3958L35.1289 51.5552L32.9821 49.3077L23.8094 58.6282L23.7769 66.5606Z" fill="url(#paint6_linear)"/>
                <path d="M32.9826 79.0539H18.8983C18.605 79.0539 18.3237 78.9355 18.1163 78.7247C17.9089 78.514 17.7924 78.2282 17.7924 77.9301V70.6588C17.7924 69.6156 18.2002 68.6152 18.9261 67.8776C19.652 67.14 20.6365 66.7256 21.6631 66.7256H30.2177C30.7245 66.7256 31.2263 66.8276 31.6941 67.0256C32.1619 67.2237 32.5865 67.5139 32.9433 67.8796C33.3002 68.2453 33.5822 68.6791 33.7732 69.1561C33.9641 69.6331 34.0603 70.1438 34.056 70.6588V77.9301C34.0561 78.2225 33.944 78.5035 33.7436 78.7133C33.5432 78.9231 33.2702 79.0453 32.9826 79.0539ZM19.9717 76.8064H31.8766V70.4935C31.881 70.2694 31.8412 70.0466 31.7598 69.8383C31.6784 69.63 31.5569 69.4402 31.4024 69.2802C31.2479 69.1201 31.0636 68.993 30.8602 68.9062C30.6568 68.8195 30.4383 68.7748 30.2177 68.7748H21.6631C21.441 68.7748 21.221 68.8193 21.0158 68.9057C20.8106 68.992 20.6241 69.1186 20.4671 69.2782C20.31 69.4378 20.1854 69.6273 20.1004 69.8358C20.0154 70.0443 19.9717 70.2678 19.9717 70.4935V76.8064Z" fill="url(#paint7_linear)"/>
                <path d="M43.4887 79.0541H42.0249C41.7373 79.0455 41.4643 78.9233 41.2639 78.7135C41.0635 78.5037 40.9514 78.2227 40.9515 77.9303C40.9514 77.6379 41.0635 77.357 41.2639 77.1472C41.4643 76.9374 41.7373 76.8152 42.0249 76.8066H43.4887C43.782 76.8066 44.0633 76.925 44.2707 77.1357C44.4781 77.3465 44.5946 77.6323 44.5946 77.9303C44.5946 78.2284 44.4781 78.5142 44.2707 78.725C44.0633 78.9357 43.782 79.0541 43.4887 79.0541Z" fill="url(#paint8_linear)"/>
                <path d="M54.1249 79.0541H50.5794C50.2861 79.0541 50.0048 78.9357 49.7974 78.725C49.59 78.5142 49.4735 78.2284 49.4735 77.9303C49.4735 77.6323 49.59 77.3465 49.7974 77.1357C50.0048 76.925 50.2861 76.8066 50.5794 76.8066H54.1249C54.4156 76.8149 54.6922 76.936 54.8978 77.1449C55.1035 77.3539 55.2226 77.6349 55.2308 77.9303C55.2308 78.2284 55.1143 78.5142 54.9069 78.725C54.6995 78.9357 54.4182 79.0541 54.1249 79.0541Z" fill="url(#paint9_linear)"/>
                <path d="M62.6472 79.0541H61.216C60.9227 79.0541 60.6415 78.9357 60.4341 78.725C60.2267 78.5142 60.1101 78.2284 60.1101 77.9303C60.1101 77.6323 60.2267 77.3465 60.4341 77.1357C60.6415 76.925 60.9227 76.8066 61.216 76.8066H62.6472C62.9405 76.8066 63.2218 76.925 63.4292 77.1357C63.6366 77.3465 63.7532 77.6323 63.7532 77.9303C63.7532 78.2284 63.6366 78.5142 63.4292 78.725C63.2218 78.9357 62.9405 79.0541 62.6472 79.0541Z" fill="url(#paint10_linear)"/>
                <path d="M42.0251 79.0541H1.0734C0.785768 79.0455 0.512756 78.9233 0.312338 78.7135C0.11192 78.5037 -0.000124386 78.2227 1.03627e-07 77.9303C-0.000124386 77.6379 0.11192 77.357 0.312338 77.1472C0.512756 76.9374 0.785768 76.8152 1.0734 76.8066H42.0251C42.3184 76.8066 42.5997 76.925 42.8071 77.1357C43.0145 77.3465 43.131 77.6323 43.131 77.9303C43.131 78.2284 43.0145 78.5142 42.8071 78.725C42.5997 78.9357 42.3184 79.0541 42.0251 79.0541Z" fill="url(#paint11_linear)"/>
                <path d="M1.07355 70.3945C0.785916 70.3859 0.512903 70.2637 0.312485 70.0539C0.112068 69.844 2.31895e-05 69.5631 0.000147679 69.2707V68.2792C-0.0151055 65.1997 1.15175 62.2355 3.25287 60.0163L4.00098 59.2561C4.09369 59.1239 4.21351 59.0137 4.35213 58.9331C4.49075 58.8525 4.64488 58.8033 4.80398 58.789C4.96308 58.7747 5.12335 58.7956 5.27376 58.8502C5.42416 58.9048 5.56111 58.9919 5.67523 59.1055C5.78936 59.219 5.87795 59.3563 5.93486 59.508C5.99178 59.6596 6.01568 59.822 6.00494 59.9839C5.99421 60.1458 5.94909 60.3035 5.87267 60.446C5.79626 60.5885 5.6904 60.7125 5.56232 60.8095L4.81416 61.5697C3.1228 63.3434 2.1678 65.7103 2.14695 68.18V69.1385C2.16513 69.294 2.15109 69.4517 2.10576 69.6014C2.06043 69.7511 1.98482 69.8896 1.88375 70.0078C1.78268 70.1261 1.65838 70.2216 1.51885 70.2881C1.37933 70.3547 1.22766 70.3909 1.07355 70.3945Z" fill="url(#paint12_linear)"/>
                <defs>
                <linearGradient id="paint0_linear" x1="104.814" y1="42.6784" x2="-54.3677" y2="-2.19828" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0E7CAA"/>
                <stop offset="1" stop-color="#0CE2E2"/>
                </linearGradient>
                <linearGradient id="paint1_linear" x1="56.5261" y1="53.5987" x2="-30.6241" y2="28.9691" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0E7CAA"/>
                <stop offset="1" stop-color="#0CE2E2"/>
                </linearGradient>
                <linearGradient id="paint2_linear" x1="36.9948" y1="59.531" x2="-24.0948" y2="42.3297" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0E7CAA"/>
                <stop offset="1" stop-color="#0CE2E2"/>
                </linearGradient>
                <linearGradient id="paint3_linear" x1="102.427" y1="40.3052" x2="-48.0749" y2="-1.98801" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0E7CAA"/>
                <stop offset="1" stop-color="#0CE2E2"/>
                </linearGradient>
                <linearGradient id="paint4_linear" x1="58.365" y1="21.3968" x2="28.5471" y2="13.008" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0E7CAA"/>
                <stop offset="1" stop-color="#0CE2E2"/>
                </linearGradient>
                <linearGradient id="paint5_linear" x1="67.1853" y1="14.766" x2="32.9201" y2="5.10766" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0E7CAA"/>
                <stop offset="1" stop-color="#0CE2E2"/>
                </linearGradient>
                <linearGradient id="paint6_linear" x1="50.0015" y1="64.4579" x2="-0.542867" y2="54.0343" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0E7CAA"/>
                <stop offset="1" stop-color="#0CE2E2"/>
                </linearGradient>
                <linearGradient id="paint7_linear" x1="46.9045" y1="76.6499" x2="-0.14063" y2="58.874" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0E7CAA"/>
                <stop offset="1" stop-color="#0CE2E2"/>
                </linearGradient>
                <linearGradient id="paint8_linear" x1="47.4727" y1="78.6158" x2="37.5654" y2="74.0163" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0E7CAA"/>
                <stop offset="1" stop-color="#0CE2E2"/>
                </linearGradient>
                <linearGradient id="paint9_linear" x1="59.779" y1="78.6158" x2="47.4074" y2="69.5389" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0E7CAA"/>
                <stop offset="1" stop-color="#0CE2E2"/>
                </linearGradient>
                <linearGradient id="paint10_linear" x1="66.6312" y1="78.6158" x2="56.724" y2="74.0163" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0E7CAA"/>
                <stop offset="1" stop-color="#0CE2E2"/>
                </linearGradient>
                <linearGradient id="paint11_linear" x1="77.2046" y1="78.6158" x2="72.6365" y2="53.5077" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0E7CAA"/>
                <stop offset="1" stop-color="#0CE2E2"/>
                </linearGradient>
                <linearGradient id="paint12_linear" x1="10.7533" y1="68.1305" x2="-8.67816" y2="65.2507" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0E7CAA"/>
                <stop offset="1" stop-color="#0CE2E2"/>
                </linearGradient>
                </defs>
                </svg>

             </div>

             :
             <div style={{position:'relative', marginTop:'20px'}} className="">
             <svg width="65" height="80" viewBox="0 0 65 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M42.0579 52.9766C40.1042 52.9714 38.1552 52.7833 36.2355 52.4147C36.0153 52.3584 35.8135 52.2444 35.6501 52.0842L12.6208 28.6837C12.4797 28.5234 12.3792 28.3306 12.328 28.1219C11.3767 23.1731 11.6355 18.0627 13.0817 13.2392C14.5279 8.41582 17.1173 4.02688 20.6225 0.457696C20.8402 0.269111 21.117 0.165527 21.4031 0.165527C21.6892 0.165527 21.966 0.269111 22.1837 0.457696L63.4283 42.4001C63.5296 42.5016 63.61 42.6225 63.6649 42.7559C63.7198 42.8892 63.7481 43.0323 63.7481 43.1768C63.7481 43.3214 63.7198 43.4645 63.6649 43.5978C63.61 43.7312 63.5296 43.8521 63.4283 43.9536C60.6275 46.8149 57.2976 49.0852 53.6299 50.6338C49.9623 52.1823 46.0295 52.9786 42.0579 52.9766ZM36.9836 50.2664C41.2683 51.0659 45.6773 50.84 49.8611 49.6065C54.0448 48.373 57.8882 46.1659 61.0863 43.1603L21.4356 2.8374C18.4671 6.08766 16.2862 9.99713 15.0665 14.2547C13.8468 18.5122 13.6219 23.0003 14.4097 27.3617L36.9836 50.2664Z" fill="#E9F7FF"/>
                 <path d="M22.7039 59.2231C22.5586 59.2246 22.4144 59.1961 22.2802 59.1392C22.146 59.0824 22.0246 58.9985 21.9233 58.8926L5.88738 42.6973C5.68081 42.4867 5.56482 42.2014 5.56482 41.9041C5.56482 41.6067 5.68081 41.3215 5.88738 41.1108L16.1335 30.6996C16.3408 30.4897 16.6215 30.3718 16.9141 30.3718C17.2067 30.3718 17.4875 30.4897 17.6948 30.6996L33.6981 46.994C33.8045 47.096 33.8892 47.2189 33.9471 47.3553C34.0049 47.4917 34.0348 47.6387 34.0348 47.7873C34.0348 47.9358 34.0049 48.0828 33.9471 48.2192C33.8892 48.3556 33.8045 48.4785 33.6981 48.5805L23.4846 58.9587C23.2675 59.1456 22.9879 59.2403 22.7039 59.2231ZM8.22934 41.838L22.7039 56.5459L31.3887 47.7211L16.9141 33.0132L8.22934 41.838Z" fill="#E9F7FF"/>
                 <path d="M10.4088 63.4866C8.6211 63.5081 6.86755 62.9888 5.37121 61.9947C3.87488 61.0005 2.70339 59.5765 2.00588 57.9039C1.30837 56.2312 1.11638 54.3855 1.45426 52.6016C1.79215 50.8178 2.64465 49.1763 3.90333 47.8863L8.19694 43.5234C8.29681 43.4205 8.41578 43.3388 8.547 43.283C8.67823 43.2272 8.81909 43.1985 8.96134 43.1985C9.10358 43.1985 9.2444 43.2272 9.37562 43.283C9.50684 43.3388 9.62586 43.4205 9.72573 43.5234L20.915 54.8932C21.1216 55.1038 21.2377 55.3891 21.2377 55.6864C21.2377 55.9838 21.1216 56.269 20.915 56.4797L16.7191 60.8094C15.8947 61.6588 14.9121 62.3329 13.8288 62.7925C12.7454 63.2522 11.5828 63.4881 10.4088 63.4866ZM9.07514 46.0023L5.59476 49.3075C4.30075 50.6223 3.57377 52.4057 3.57377 54.2652C3.57377 56.1247 4.30075 57.9081 5.59476 59.2229C6.88877 60.5378 8.64383 61.2765 10.4738 61.2765C12.3038 61.2765 14.0589 60.5378 15.3529 59.2229L18.8659 55.6534L9.07514 46.0023Z" fill="#E9F7FF"/>
                 <path d="M54.6136 50.0678C54.4677 50.0734 54.3224 50.0468 54.1876 49.9897C54.0528 49.9327 53.9318 49.8465 53.833 49.7373L14.8004 10.0754C14.6441 9.90543 14.5418 9.69148 14.507 9.46152C14.4722 9.23157 14.5064 8.9963 14.6052 8.78642C16.0888 5.64182 18.0787 2.77093 20.4927 0.292157C20.7104 0.103571 20.9871 0 21.2733 0C21.5594 0 21.8362 0.103571 22.0539 0.292157L63.2985 42.2346C63.3997 42.3361 63.4801 42.457 63.535 42.5903C63.59 42.7237 63.6182 42.8668 63.6182 43.0113C63.6182 43.1558 63.59 43.2989 63.535 43.4323C63.4801 43.5656 63.3997 43.6865 63.2985 43.788C60.875 46.269 58.0466 48.3043 54.9389 49.8034C54.8492 49.9129 54.7385 50.0028 54.6136 50.0678ZM17.0448 9.21608L54.8088 47.5889C57.0949 46.418 59.208 44.9272 61.0866 43.16L21.4359 2.83713C19.6909 4.74849 18.2133 6.89498 17.0448 9.21608Z" fill="#E9F7FF"/>
                 <path d="M42.0254 23.329C41.8846 23.332 41.7448 23.304 41.6157 23.247C41.4866 23.1899 41.3711 23.1052 41.2773 22.9985C41.1709 22.8965 41.0862 22.7736 41.0284 22.6372C40.9705 22.5008 40.9406 22.3538 40.9406 22.2052C40.9406 22.0567 40.9705 21.9097 41.0284 21.7733C41.0862 21.6369 41.1709 21.514 41.2773 21.412L48.8235 13.744C48.9234 13.6411 49.0424 13.5594 49.1736 13.5036C49.3049 13.4478 49.4457 13.4191 49.5879 13.4191C49.7302 13.4191 49.871 13.4478 50.0023 13.5036C50.1335 13.5594 50.2525 13.6411 50.3523 13.744C50.5589 13.9547 50.6749 14.2399 50.6749 14.5373C50.6749 14.8346 50.5589 15.1199 50.3523 15.3305L42.741 22.8663C42.6619 22.9916 42.557 23.0979 42.4334 23.1778C42.3098 23.2577 42.1706 23.3093 42.0254 23.329Z" fill="#E9F7FF"/>
                 <path d="M52.7589 16.9831C51.8373 16.9845 50.9297 16.7544 50.1167 16.3135C49.3037 15.8725 48.6104 15.2343 48.0986 14.4556C47.5867 13.6768 47.2721 12.7817 47.1827 11.8496C47.0934 10.9176 47.232 9.97756 47.5862 9.11306C47.9405 8.24856 48.4994 7.48637 49.2134 6.89422C49.9273 6.30208 50.7742 5.89831 51.6787 5.71881C52.5832 5.53932 53.5173 5.58964 54.3981 5.86532C55.2788 6.141 56.0789 6.6335 56.7272 7.29903C57.5035 8.09589 58.0305 9.10818 58.242 10.2086C58.4535 11.309 58.34 12.4484 57.9159 13.4835C57.4917 14.5186 56.7759 15.4031 55.8583 16.0258C54.9407 16.6485 53.8623 16.9816 52.7589 16.9831ZM52.7589 7.86091C52.0528 7.77127 51.3372 7.91942 50.7218 8.28264C50.1065 8.64585 49.6254 9.2041 49.3522 9.87179C49.079 10.5395 49.0289 11.2798 49.2094 11.9792C49.39 12.6787 49.7913 13.2986 50.3519 13.7441C50.6568 14.0849 51.0284 14.3572 51.4429 14.5436C51.8574 14.7299 52.3057 14.8262 52.7589 14.8262C53.2122 14.8262 53.6604 14.7299 54.0749 14.5436C54.4894 14.3572 54.8611 14.0849 55.1659 13.7441C55.4961 13.4346 55.7596 13.059 55.9398 12.6409C56.12 12.2228 56.2131 11.7712 56.2131 11.3148C56.2131 10.8584 56.12 10.4068 55.9398 9.98875C55.7596 9.57065 55.4961 9.19501 55.1659 8.88551C54.8561 8.55512 54.4822 8.29351 54.068 8.11717C53.6537 7.94083 53.208 7.85357 52.7589 7.86091Z" fill="#E9F7FF"/>
                 <path d="M29.0789 68.7751H22.5734C22.2857 68.7752 22.0092 68.6614 21.8027 68.4577C21.5962 68.2541 21.476 67.9767 21.4675 67.6844V58.1656C21.4717 57.8685 21.5881 57.5845 21.7928 57.3723L32.0389 46.9611C32.1387 46.8582 32.2577 46.7764 32.3889 46.7206C32.5202 46.6648 32.661 46.6361 32.8033 46.6361C32.9455 46.6361 33.0863 46.6648 33.2175 46.7206C33.3488 46.7764 33.4677 46.8582 33.5676 46.9611L37.0805 50.5306C37.2409 50.6839 37.3492 50.8847 37.3902 51.1045C37.4311 51.3242 37.4024 51.5514 37.3083 51.7536L29.9571 68.2793C29.8638 68.4306 29.7343 68.5553 29.5808 68.642C29.4272 68.7287 29.2545 68.7745 29.0789 68.7751ZM23.7769 66.5607H28.3958L35.1289 51.5552L32.9821 49.3077L23.8094 58.6283L23.7769 66.5607Z" fill="#E9F7FF"/>
                 <path d="M32.9826 79.0538H18.8983C18.605 79.0538 18.3237 78.9354 18.1163 78.7247C17.9089 78.5139 17.7924 78.2281 17.7924 77.9301V70.6587C17.7924 69.6156 18.2002 68.6152 18.9261 67.8776C19.652 67.14 20.6365 66.7256 21.6631 66.7256H30.2177C30.7245 66.7256 31.2263 66.8275 31.6941 67.0256C32.1619 67.2236 32.5865 67.5139 32.9433 67.8795C33.3002 68.2452 33.5822 68.6791 33.7732 69.1561C33.9641 69.6331 34.0603 70.1438 34.056 70.6587V77.9301C34.0561 78.2225 33.944 78.5034 33.7436 78.7132C33.5432 78.923 33.2702 79.0452 32.9826 79.0538ZM19.9717 76.8063H31.8766V70.4935C31.881 70.2693 31.8412 70.0466 31.7598 69.8382C31.6784 69.6299 31.5569 69.4402 31.4024 69.2801C31.2479 69.1201 31.0636 68.993 30.8602 68.9062C30.6568 68.8194 30.4383 68.7747 30.2177 68.7748H21.6631C21.441 68.7748 21.221 68.8192 21.0158 68.9056C20.8106 68.992 20.6241 69.1186 20.4671 69.2782C20.31 69.4378 20.1854 69.6272 20.1004 69.8358C20.0154 70.0443 19.9717 70.2678 19.9717 70.4935V76.8063Z" fill="#E9F7FF"/>
                 <path d="M43.4887 79.054H42.0249C41.7373 79.0454 41.4643 78.9232 41.2639 78.7134C41.0635 78.5036 40.9514 78.2227 40.9515 77.9303C40.9514 77.6379 41.0635 77.3569 41.2639 77.1471C41.4643 76.9373 41.7373 76.8151 42.0249 76.8065H43.4887C43.782 76.8065 44.0633 76.9249 44.2707 77.1357C44.4781 77.3464 44.5946 77.6322 44.5946 77.9303C44.5946 78.2283 44.4781 78.5141 44.2707 78.7249C44.0633 78.9356 43.782 79.054 43.4887 79.054Z" fill="#E9F7FF"/>
                 <path d="M54.1249 79.054H50.5794C50.2861 79.054 50.0048 78.9356 49.7974 78.7249C49.59 78.5141 49.4735 78.2283 49.4735 77.9303C49.4735 77.6322 49.59 77.3464 49.7974 77.1357C50.0048 76.9249 50.2861 76.8065 50.5794 76.8065H54.1249C54.4156 76.8148 54.6922 76.9359 54.8978 77.1449C55.1035 77.3539 55.2226 77.6349 55.2308 77.9303C55.2308 78.2283 55.1143 78.5141 54.9069 78.7249C54.6995 78.9356 54.4182 79.054 54.1249 79.054Z" fill="#E9F7FF"/>
                 <path d="M62.6472 79.054H61.216C60.9227 79.054 60.6415 78.9356 60.4341 78.7249C60.2267 78.5141 60.1101 78.2283 60.1101 77.9303C60.1101 77.6322 60.2267 77.3464 60.4341 77.1357C60.6415 76.9249 60.9227 76.8065 61.216 76.8065H62.6472C62.9405 76.8065 63.2218 76.9249 63.4292 77.1357C63.6366 77.3464 63.7532 77.6322 63.7532 77.9303C63.7532 78.2283 63.6366 78.5141 63.4292 78.7249C63.2218 78.9356 62.9405 79.054 62.6472 79.054Z" fill="#E9F7FF"/>
                 <path d="M42.0251 79.054H1.0734C0.785768 79.0454 0.512756 78.9232 0.312338 78.7134C0.11192 78.5036 -0.000124386 78.2227 1.03627e-07 77.9303C-0.000124386 77.6379 0.11192 77.3569 0.312338 77.1471C0.512756 76.9373 0.785768 76.8151 1.0734 76.8065H42.0251C42.3184 76.8065 42.5997 76.9249 42.8071 77.1357C43.0145 77.3464 43.131 77.6322 43.131 77.9303C43.131 78.2283 43.0145 78.5141 42.8071 78.7249C42.5997 78.9356 42.3184 79.054 42.0251 79.054Z" fill="#E9F7FF"/>
                 <path d="M1.07355 70.3945C0.785916 70.3859 0.512903 70.2637 0.312485 70.0539C0.112068 69.8441 2.31895e-05 69.5631 0.000147679 69.2707V68.2792C-0.0151055 65.1997 1.15175 62.2356 3.25287 60.0163L4.00098 59.2561C4.09369 59.1239 4.21351 59.0138 4.35213 58.9331C4.49075 58.8525 4.64488 58.8034 4.80398 58.789C4.96308 58.7747 5.12335 58.7956 5.27376 58.8502C5.42416 58.9049 5.56111 58.9919 5.67523 59.1055C5.78936 59.219 5.87795 59.3563 5.93486 59.508C5.99178 59.6596 6.01568 59.822 6.00494 59.9839C5.99421 60.1459 5.94909 60.3035 5.87267 60.446C5.79626 60.5886 5.6904 60.7126 5.56232 60.8095L4.81416 61.5697C3.1228 63.3435 2.1678 65.7103 2.14695 68.18V69.1385C2.16513 69.2941 2.15109 69.4517 2.10576 69.6015C2.06043 69.7512 1.98482 69.8896 1.88375 70.0079C1.78268 70.1261 1.65838 70.2216 1.51885 70.2882C1.37933 70.3548 1.22766 70.391 1.07355 70.3945Z" fill="#E9F7FF"/>
             </svg>
         
             <svg style={{color: 'red', position: 'absolute', top: '58px', left: '50px'}}  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M8.00003 0.888916C6.59359 0.888916 5.21872 1.30598 4.04931 2.08735C2.87989 2.86873 1.96844 3.97934 1.43022 5.27872C0.891997 6.57811 0.751173 8.00792 1.02556 9.38734C1.29994 10.7668 1.97721 12.0338 2.97172 13.0283C3.96622 14.0228 5.2333 14.7001 6.61272 14.9745C7.99214 15.2489 9.42195 15.1081 10.7213 14.5698C12.0207 14.0316 13.1313 13.1202 13.9127 11.9507C14.6941 10.7813 15.1111 9.40647 15.1111 8.00003C15.1111 6.11404 14.3619 4.3053 13.0283 2.97171C11.6948 1.63812 9.88601 0.888916 8.00003 0.888916ZM11.5556 10.7111C11.6735 10.829 11.7397 10.9889 11.7397 11.1556C11.7397 11.3223 11.6735 11.4822 11.5556 11.6C11.4377 11.7179 11.2778 11.7841 11.1111 11.7841C10.9444 11.7841 10.7846 11.7179 10.6667 11.6L8.00003 8.93336L5.33336 11.6089C5.275 11.6673 5.20571 11.7136 5.12945 11.7452C5.05319 11.7768 4.97146 11.793 4.88892 11.793C4.80638 11.793 4.72465 11.7768 4.64839 11.7452C4.57213 11.7136 4.50284 11.6673 4.44447 11.6089C4.38611 11.5506 4.33981 11.4813 4.30822 11.405C4.27664 11.3287 4.26038 11.247 4.26038 11.1645C4.26038 11.0819 4.27664 11.0002 4.30822 10.9239C4.33981 10.8477 4.38611 10.7784 4.44447 10.72L7.11114 8.03558L4.36892 5.27114C4.25105 5.15326 4.18482 4.99339 4.18482 4.82669C4.18482 4.65999 4.25105 4.50012 4.36892 4.38225C4.48679 4.26438 4.64666 4.19815 4.81336 4.19815C4.98006 4.19815 5.13993 4.26438 5.25781 4.38225L8.00003 7.15558L10.7423 4.41336C10.8006 4.355 10.8699 4.3087 10.9462 4.27711C11.0224 4.24552 11.1042 4.22927 11.1867 4.22927C11.2692 4.22927 11.351 4.24552 11.4272 4.27711C11.5035 4.3087 11.5728 4.355 11.6311 4.41336C11.6895 4.47173 11.7358 4.54102 11.7674 4.61727C11.799 4.69353 11.8152 4.77526 11.8152 4.85781C11.8152 4.94035 11.799 5.02208 11.7674 5.09834C11.7358 5.17459 11.6895 5.24388 11.6311 5.30225L8.88892 8.03558L11.5556 10.7111Z" fill="#E31863"/>
             </svg>
 
         
            </div>

        }
        
        </>
    )
}

