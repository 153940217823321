
export const FirstDashboardSatellite = () => {

    return (
       
        <svg width="37" height="45" viewBox="0 0 37 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.9406 30.1559C22.8285 30.153 21.7191 30.0459 20.6263 29.8361C20.501 29.804 20.3861 29.7392 20.2931 29.648L7.1841 16.3277C7.10378 16.2364 7.04656 16.1266 7.01744 16.0078C6.47594 13.1908 6.62327 10.2818 7.44648 7.5362C8.2697 4.79056 9.74363 2.29224 11.7389 0.26055C11.8629 0.153201 12.0204 0.0942383 12.1833 0.0942383C12.3461 0.0942383 12.5037 0.153201 12.6276 0.26055L36.1053 24.1355C36.1629 24.1932 36.2087 24.2621 36.24 24.338C36.2712 24.4139 36.2873 24.4953 36.2873 24.5776C36.2873 24.6599 36.2712 24.7413 36.24 24.8172C36.2087 24.8931 36.1629 24.962 36.1053 25.0197C34.511 26.6485 32.6155 27.9408 30.5278 28.8223C28.4401 29.7038 26.2014 30.157 23.9406 30.1559ZM21.0522 28.6132C23.4911 29.0683 26.0009 28.9397 28.3824 28.2376C30.764 27.5354 32.9517 26.2791 34.7722 24.5682L12.2018 1.61515C10.512 3.4653 9.27057 5.69069 8.57627 8.11421C7.88196 10.5377 7.75394 13.0925 8.20243 15.5751L21.0522 28.6132Z" fill="url(#paint0_linear)"/>
        <path d="M12.9237 33.7116C12.841 33.7125 12.7589 33.6962 12.6825 33.6639C12.6061 33.6315 12.537 33.5838 12.4794 33.5235L3.35121 24.3046C3.23363 24.1847 3.1676 24.0223 3.1676 23.8531C3.1676 23.6838 3.23363 23.5214 3.35121 23.4015L9.18361 17.4751C9.3016 17.3557 9.46138 17.2886 9.62796 17.2886C9.79454 17.2886 9.95435 17.3557 10.0723 17.4751L19.182 26.7504C19.2425 26.8085 19.2907 26.8785 19.3236 26.9561C19.3566 27.0337 19.3736 27.1174 19.3736 27.202C19.3736 27.2865 19.3566 27.3702 19.3236 27.4478C19.2907 27.5255 19.2425 27.5955 19.182 27.6535L13.3681 33.5611C13.2445 33.6675 13.0854 33.7214 12.9237 33.7116ZM4.68433 23.8155L12.9237 32.1877L17.8673 27.1643L9.62796 18.7921L4.68433 23.8155Z" fill="url(#paint1_linear)"/>
        <path d="M5.92497 36.1386C4.90737 36.1509 3.9092 35.8552 3.05744 35.2893C2.20568 34.7234 1.53883 33.9129 1.14179 32.9607C0.744744 32.0086 0.635455 30.958 0.82779 29.9425C1.02013 28.9271 1.50539 27.9927 2.22188 27.2584L4.66593 24.7749C4.72278 24.7164 4.7905 24.6698 4.8652 24.6381C4.93989 24.6063 5.02008 24.59 5.10105 24.59C5.18202 24.59 5.26217 24.6063 5.33687 24.6381C5.41157 24.6698 5.47932 24.7164 5.53616 24.7749L11.9055 31.2469C12.0231 31.3668 12.0891 31.5292 12.0891 31.6985C12.0891 31.8678 12.0231 32.0301 11.9055 32.15L9.51698 34.6146C9.0477 35.0981 8.48842 35.4819 7.87173 35.7435C7.25504 36.0051 6.59326 36.1394 5.92497 36.1386V36.1386ZM5.16583 26.186L3.18469 28.0674C2.4481 28.8159 2.03428 29.831 2.03428 30.8895C2.03428 31.948 2.4481 32.9631 3.18469 33.7116C3.92128 34.46 4.92031 34.8805 5.96201 34.8805C7.00371 34.8805 8.00274 34.46 8.73933 33.7116L10.739 31.6797L5.16583 26.186Z" fill="url(#paint2_linear)"/>
        <path d="M31.0877 28.5001C31.0046 28.5033 30.9219 28.4882 30.8452 28.4557C30.7685 28.4232 30.6996 28.3742 30.6434 28.312L8.42478 5.73524C8.33579 5.63848 8.2776 5.51669 8.25778 5.38579C8.23796 5.25489 8.25745 5.12097 8.31369 5.0015C9.1582 3.2115 10.2909 1.5773 11.665 0.166304C11.7889 0.0589557 11.9465 0 12.1093 0C12.2722 0 12.4298 0.0589557 12.5537 0.166304L36.0314 24.0412C36.089 24.099 36.1348 24.1678 36.166 24.2437C36.1973 24.3196 36.2134 24.4011 36.2134 24.4834C36.2134 24.5656 36.1973 24.6471 36.166 24.723C36.1348 24.7989 36.089 24.8677 36.0314 24.9255C34.6519 26.3377 33.0419 27.4963 31.2729 28.3496C31.2218 28.412 31.1588 28.4632 31.0877 28.5001ZM9.70235 5.24608L31.1988 27.0891C32.5001 26.4226 33.7029 25.5739 34.7723 24.568L12.2019 1.61498C11.2086 2.70299 10.3675 3.92483 9.70235 5.24608V5.24608Z" fill="url(#paint3_linear)"/>
        <path d="M23.9221 13.2796C23.8419 13.2814 23.7624 13.2654 23.6889 13.233C23.6154 13.2005 23.5496 13.1522 23.4962 13.0915C23.4357 13.0335 23.3875 12.9635 23.3545 12.8858C23.3216 12.8082 23.3046 12.7245 23.3046 12.64C23.3046 12.5554 23.3216 12.4717 23.3545 12.3941C23.3875 12.3165 23.4357 12.2465 23.4962 12.1884L27.7918 7.8236C27.8487 7.76501 27.9164 7.71848 27.9911 7.68673C28.0658 7.65497 28.1459 7.63861 28.2269 7.63861C28.3079 7.63861 28.3881 7.65497 28.4628 7.68673C28.5375 7.71848 28.6052 7.76501 28.662 7.8236C28.7796 7.94349 28.8456 8.10586 28.8456 8.27513C28.8456 8.4444 28.7796 8.60677 28.662 8.72667L24.3294 13.0162C24.2844 13.0876 24.2247 13.1481 24.1543 13.1936C24.084 13.2391 24.0047 13.2684 23.9221 13.2796V13.2796Z" fill="url(#paint4_linear)"/>
        <path d="M30.032 9.66732C29.5074 9.66807 28.9908 9.53712 28.528 9.28611C28.0652 9.03511 27.6706 8.67183 27.3792 8.22855C27.0879 7.78526 26.9088 7.2757 26.8579 6.74516C26.807 6.21462 26.8859 5.67952 27.0876 5.18742C27.2892 4.69532 27.6074 4.26146 28.0138 3.9244C28.4202 3.58733 28.9023 3.35749 29.4171 3.25532C29.932 3.15314 30.4637 3.18179 30.9651 3.33871C31.4665 3.49564 31.9219 3.77598 32.2909 4.15483C32.7328 4.60842 33.0328 5.18464 33.1532 5.81104C33.2736 6.43743 33.209 7.08602 32.9675 7.67521C32.7261 8.2644 32.3186 8.7679 31.7963 9.12236C31.274 9.47682 30.6601 9.66642 30.032 9.66732V9.66732ZM30.032 4.47466C29.6301 4.42364 29.2227 4.50797 28.8725 4.71472C28.5222 4.92148 28.2483 5.23925 28.0928 5.61932C27.9373 5.99939 27.9088 6.4208 28.0116 6.81893C28.1144 7.21707 28.3428 7.56998 28.6619 7.82355C28.8354 8.01756 29.0469 8.17256 29.2829 8.27863C29.5188 8.38471 29.774 8.43951 30.032 8.43951C30.29 8.43951 30.5452 8.38471 30.7811 8.27863C31.0171 8.17256 31.2287 8.01756 31.4022 7.82355C31.5901 7.64737 31.7401 7.43355 31.8427 7.19555C31.9453 6.95756 31.9983 6.70055 31.9983 6.44072C31.9983 6.1809 31.9453 5.92389 31.8427 5.68589C31.7401 5.4479 31.5901 5.23407 31.4022 5.0579C31.2258 4.86983 31.013 4.72091 30.7772 4.62053C30.5414 4.52015 30.2877 4.47049 30.032 4.47466Z" fill="url(#paint5_linear)"/>
        <path d="M16.5527 39.1489H12.8496C12.6858 39.149 12.5284 39.0842 12.4109 38.9682C12.2933 38.8523 12.2249 38.6944 12.2201 38.528V33.1096C12.2225 32.9405 12.2887 32.7788 12.4052 32.6581L18.2376 26.7317C18.2945 26.6731 18.3622 26.6266 18.4369 26.5948C18.5116 26.5631 18.5918 26.5467 18.6727 26.5467C18.7537 26.5467 18.8339 26.5631 18.9086 26.5948C18.9833 26.6266 19.051 26.6731 19.1078 26.7317L21.1075 28.7636C21.1988 28.8508 21.2605 28.9651 21.2837 29.0902C21.307 29.2153 21.2907 29.3447 21.2371 29.4597L17.0526 38.8667C16.9995 38.9528 16.9258 39.0238 16.8384 39.0731C16.751 39.1225 16.6527 39.1485 16.5527 39.1489ZM13.5347 37.8884H16.1639L19.9966 29.3468L18.7746 28.0675L13.5532 33.373L13.5347 37.8884Z" fill="url(#paint6_linear)"/>
        <path d="M18.7748 44.9999H10.7576C10.5906 44.9999 10.4305 44.9325 10.3124 44.8126C10.1944 44.6926 10.1281 44.5299 10.1281 44.3602V40.2212C10.1281 39.6274 10.3602 39.0579 10.7734 38.638C11.1866 38.2182 11.747 37.9823 12.3314 37.9823H17.201C17.4894 37.9823 17.7751 38.0403 18.0413 38.1531C18.3076 38.2658 18.5493 38.431 18.7525 38.6392C18.9556 38.8473 19.1161 39.0943 19.2248 39.3658C19.3335 39.6373 19.3882 39.928 19.3858 40.2212V44.3602C19.3859 44.5267 19.3221 44.6866 19.208 44.806C19.0939 44.9255 18.9385 44.995 18.7748 44.9999V44.9999ZM11.3686 43.7206H18.1453V40.1271C18.1477 39.9995 18.1251 39.8727 18.0788 39.7541C18.0324 39.6355 17.9632 39.5275 17.8753 39.4364C17.7874 39.3453 17.6824 39.273 17.5667 39.2236C17.4509 39.1742 17.3265 39.1487 17.201 39.1488H12.3314C12.205 39.1488 12.0797 39.1741 11.9629 39.2232C11.8461 39.2724 11.74 39.3445 11.6506 39.4353C11.5612 39.5262 11.4903 39.634 11.4419 39.7527C11.3935 39.8714 11.3686 39.9986 11.3686 40.1271V43.7206Z" fill="url(#paint7_linear)"/>
        <path d="M24.755 45H23.9218C23.7581 44.9951 23.6027 44.9256 23.4886 44.8061C23.3745 44.6867 23.3107 44.5268 23.3108 44.3603C23.3107 44.1939 23.3745 44.034 23.4886 43.9145C23.6027 43.7951 23.7581 43.7255 23.9218 43.7206H24.755C24.922 43.7206 25.0821 43.788 25.2001 43.908C25.3182 44.028 25.3845 44.1907 25.3845 44.3603C25.3845 44.53 25.3182 44.6927 25.2001 44.8126C25.0821 44.9326 24.922 45 24.755 45V45Z" fill="url(#paint8_linear)"/>
        <path d="M30.8096 45H28.7914C28.6244 45 28.4643 44.9326 28.3462 44.8126C28.2282 44.6927 28.1619 44.53 28.1619 44.3603C28.1619 44.1907 28.2282 44.028 28.3462 43.908C28.4643 43.788 28.6244 43.7206 28.7914 43.7206H30.8096C30.9751 43.7254 31.1325 43.7943 31.2495 43.9133C31.3666 44.0322 31.4344 44.1922 31.4391 44.3603C31.4391 44.53 31.3728 44.6927 31.2547 44.8126C31.1367 44.9326 30.9765 45 30.8096 45V45Z" fill="url(#paint9_linear)"/>
        <path d="M35.6608 45H34.8461C34.6791 45 34.519 44.9326 34.401 44.8126C34.2829 44.6927 34.2166 44.53 34.2166 44.3603C34.2166 44.1907 34.2829 44.028 34.401 43.908C34.519 43.788 34.6791 43.7206 34.8461 43.7206H35.6608C35.8277 43.7206 35.9878 43.788 36.1059 43.908C36.224 44.028 36.2903 44.1907 36.2903 44.3603C36.2903 44.53 36.224 44.6927 36.1059 44.8126C35.9878 44.9326 35.8277 45 35.6608 45V45Z" fill="url(#paint10_linear)"/>
        <path d="M23.922 45H0.611014C0.447283 44.9951 0.291876 44.9256 0.177792 44.8061C0.0637084 44.6867 -7.08044e-05 44.5268 5.89876e-08 44.3603C-7.08044e-05 44.1939 0.0637084 44.034 0.177792 43.9145C0.291876 43.7951 0.447283 43.7255 0.611014 43.7206H23.922C24.089 43.7206 24.2491 43.788 24.3671 43.908C24.4852 44.028 24.5515 44.1907 24.5515 44.3603C24.5515 44.53 24.4852 44.6927 24.3671 44.8126C24.2491 44.9326 24.089 45 23.922 45V45Z" fill="url(#paint11_linear)"/>
        <path d="M0.611098 40.0707C0.447367 40.0658 0.29196 39.9963 0.177876 39.8768C0.0637924 39.7574 1.32002e-05 39.5975 8.40636e-05 39.431V38.8666C-0.00859852 37.1137 0.655614 35.4264 1.85163 34.1631L2.27748 33.7304C2.33026 33.6552 2.39846 33.5924 2.47737 33.5466C2.55627 33.5007 2.64401 33.4727 2.73458 33.4645C2.82514 33.4564 2.91637 33.4683 3.00198 33.4994C3.0876 33.5305 3.16556 33.58 3.23052 33.6447C3.29548 33.7093 3.34591 33.7875 3.37831 33.8738C3.4107 33.9601 3.42431 34.0525 3.4182 34.1447C3.41209 34.2369 3.3864 34.3266 3.34291 34.4077C3.29941 34.4889 3.23915 34.5595 3.16624 34.6147L2.74037 35.0474C1.7776 36.057 1.23398 37.4043 1.22211 38.8102V39.3558C1.23246 39.4443 1.22446 39.5341 1.19866 39.6193C1.17286 39.7045 1.12982 39.7833 1.07229 39.8506C1.01476 39.9179 0.944 39.9723 0.864579 40.0102C0.785157 40.0481 0.698821 40.0687 0.611098 40.0707V40.0707Z" fill="url(#paint12_linear)"/>
        <defs>
        <linearGradient id="paint0_linear" x1="16.5607" y1="0.0942384" x2="16.5607" y2="31.0954" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0CE2E2"/>
        <stop offset="1" stop-color="#27AAE1"/>
        </linearGradient>
        <linearGradient id="paint1_linear" x1="8.5696" y1="17.2886" x2="8.5696" y2="34.226" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0CE2E2"/>
        <stop offset="1" stop-color="#27AAE1"/>
        </linearGradient>
        <linearGradient id="paint2_linear" x1="4.51994" y1="24.59" x2="4.51994" y2="36.4999" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0CE2E2"/>
        <stop offset="1" stop-color="#27AAE1"/>
        </linearGradient>
        <linearGradient id="paint3_linear" x1="17.5713" y1="1.53361e-07" x2="17.5713" y2="29.3912" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0CE2E2"/>
        <stop offset="1" stop-color="#27AAE1"/>
        </linearGradient>
        <linearGradient id="paint4_linear" x1="25.1516" y1="7.63861" x2="25.1516" y2="13.4561" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0CE2E2"/>
        <stop offset="1" stop-color="#27AAE1"/>
        </linearGradient>
        <linearGradient id="paint5_linear" x1="28.966" y1="3.19531" x2="28.966" y2="9.86957" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0CE2E2"/>
        <stop offset="1" stop-color="#27AAE1"/>
        </linearGradient>
        <linearGradient id="paint6_linear" x1="15.2448" y1="26.5467" x2="15.2448" y2="39.5427" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0CE2E2"/>
        <stop offset="1" stop-color="#27AAE1"/>
        </linearGradient>
        <linearGradient id="paint7_linear" x1="13.214" y1="37.9823" x2="13.214" y2="45.2192" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0CE2E2"/>
        <stop offset="1" stop-color="#27AAE1"/>
        </linearGradient>
        <linearGradient id="paint8_linear" x1="24.002" y1="43.7206" x2="24.002" y2="45.04" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0CE2E2"/>
        <stop offset="1" stop-color="#27AAE1"/>
        </linearGradient>
        <linearGradient id="paint9_linear" x1="29.2543" y1="43.7206" x2="29.2543" y2="45.04" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0CE2E2"/>
        <stop offset="1" stop-color="#27AAE1"/>
        </linearGradient>
        <linearGradient id="paint10_linear" x1="34.9078" y1="43.7206" x2="34.9078" y2="45.04" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0CE2E2"/>
        <stop offset="1" stop-color="#27AAE1"/>
        </linearGradient>
        <linearGradient id="paint11_linear" x1="8.18384" y1="43.7206" x2="8.18384" y2="45.04" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0CE2E2"/>
        <stop offset="1" stop-color="#27AAE1"/>
        </linearGradient>
        <linearGradient id="paint12_linear" x1="1.13987" y1="33.462" x2="1.13987" y2="40.2772" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0CE2E2"/>
        <stop offset="1" stop-color="#27AAE1"/>
        </linearGradient>
        </defs>
        </svg>
        
        
        
        
        
        
    )
}