
export const CarbonSatellite = () => {

    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.5 11H21C20.9978 9.01155 20.2069 7.10516 18.8009 5.69911C17.3948 4.29306 15.4885 3.50218 13.5 3.5V2C15.8861 2.00278 18.1737 2.95188 19.8609 4.63911C21.5481 6.32633 22.4972 8.6139 22.5 11Z" fill="#7CB3BF"/>
        <path d="M19.5 11H18C17.9986 9.80695 17.5241 8.66317 16.6804 7.81955C15.8368 6.97594 14.693 6.50139 13.5 6.5V5C15.0907 5.00198 16.6157 5.63476 17.7404 6.75955C18.8652 7.88434 19.498 9.40931 19.5 11Z" fill="#7CB3BF"/>
        <path d="M12 21.5V19.22C12.9789 19.321 13.9681 19.2266 14.9103 18.9424C15.8525 18.6581 16.7288 18.1896 17.4885 17.564C17.6383 17.4419 17.761 17.2898 17.8485 17.1175C17.9361 16.9452 17.9867 16.7565 17.997 16.5635C18.0062 16.3755 17.976 16.1876 17.9084 16.012C17.8407 15.8363 17.737 15.6767 17.604 15.5435L13.8105 11.75L15.75 9.81052L14.6895 8.75002L12.75 10.6895L8.9565 6.89527C8.82281 6.76297 8.66315 6.65981 8.48759 6.5923C8.31203 6.52478 8.12439 6.49439 7.9365 6.50302C7.74341 6.51329 7.55461 6.56391 7.38229 6.65163C7.20996 6.73934 7.05793 6.8622 6.936 7.01227C5.9097 8.26289 5.31729 9.81298 5.2479 11.4293C5.17852 13.0456 5.6359 14.6408 6.55125 15.9748L4.71 21.5H1.5V23H22.5V21.5H12ZM8.01 8.07052L16.4295 16.49C15.2749 17.3858 13.8331 17.8294 12.3747 17.7377C10.9162 17.646 9.5414 17.0252 8.50809 15.9919C7.47479 14.9586 6.85405 13.5838 6.76234 12.1253C6.67064 10.6669 7.11427 9.22512 8.01 8.07052ZM10.5 21.5H6.29025L7.69725 17.2798C8.50157 18.0203 9.4582 18.5758 10.5 18.9073V21.5Z" fill="#7CB3BF"/>
        </svg> 
    )
}