

export const NoDataSatellite = () => {

    return (
        <svg width="177" height="164" viewBox="0 0 177 164" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.42086 137.403C1.00143 138.226 0.803109 139.144 0.8453 140.067L1.36632 137.645L1.15327 138.166C-0.0434279 140.828 1.74307 144.9 5.13819 144.372C5.3111 144.329 5.4891 144.309 5.66737 144.313L3.24602 143.792L2.37579 143.188L1.01582 141.107C1.34465 142.144 2.00655 143.042 2.89865 143.664C3.79075 144.285 4.86311 144.594 5.94907 144.543C7.03503 144.493 8.0738 144.084 8.90394 143.382C9.73408 142.68 10.3091 141.723 10.5397 140.661C10.812 139.486 10.6447 138.252 10.0693 137.192C9.494 136.132 8.55052 135.32 7.41708 134.908C6.28365 134.497 5.03874 134.515 3.91759 134.959C2.79643 135.403 1.87664 136.242 1.33196 137.318L1.42086 137.403Z" fill="url(#paint0_linear)"/>
            <path d="M58.7353 7.62835C58.5791 8.38388 58.7214 9.17052 59.1323 9.82341C59.5433 10.4763 60.191 10.9447 60.9397 11.1304C61.6931 11.2924 62.4799 11.1517 63.1304 10.7387C63.7809 10.3256 64.243 9.6733 64.4169 8.9225C64.5598 8.16698 64.4082 7.38542 63.9931 6.73819C63.5781 6.09097 62.9311 5.62713 62.185 5.44189C61.4393 5.2935 60.6651 5.43781 60.0228 5.84486C59.3806 6.25192 58.9196 6.89056 58.7353 7.62835Z" fill="url(#paint1_linear)"/>
            <path d="M38.8875 24.5546C38.3553 28.889 45.0193 30.2789 45.5425 26.018C46.0656 21.7572 39.4197 20.2203 38.8875 24.5546Z" fill="url(#paint2_linear)"/>
            <path d="M116.836 39.0476C125.479 21.6898 154.761 26.3043 162.014 42.5316C164.03 47.2024 164.644 52.3588 163.781 57.3725C162.918 62.3862 160.616 67.0408 157.155 70.7691C149.415 79.3637 139.796 79.9724 129.039 78.7261C128.854 78.702 128.667 78.742 128.508 78.8394C128.349 78.9368 128.228 79.0857 128.166 79.2613C128.104 79.4369 128.104 79.6286 128.166 79.8043C128.228 79.98 128.348 80.1291 128.507 80.2268C151.51 93.4664 174.426 61.8781 165.022 41.1112C157.044 23.5023 122.336 16.929 115.027 38.4028C114.625 39.4472 116.346 40.0065 116.836 39.0476Z" fill="url(#paint3_linear)"/>
            <path d="M115.619 36.0147C104.124 51.7048 113.513 80.8964 134.94 81.0665C135.123 81.0602 135.299 80.9925 135.438 80.8742C135.578 80.7559 135.674 80.594 135.71 80.4146C135.747 80.2352 135.722 80.0488 135.639 79.8854C135.556 79.7221 135.421 79.5913 135.255 79.5143C126.169 75.5897 118.509 71.9895 114.919 61.954C111.85 53.3495 113.273 44.9983 116.973 36.9266C117.293 36.1456 116.177 35.3127 115.619 36.0147Z" fill="url(#paint4_linear)"/>
            <path d="M132.803 32.0837C137.446 32.1032 142.074 32.6297 146.603 33.6538C146.759 33.6847 146.92 33.6588 147.059 33.5807C147.197 33.5026 147.304 33.3776 147.358 33.2281C147.412 33.0786 147.411 32.9147 147.355 32.7658C147.299 32.617 147.192 32.4931 147.053 32.4165C142.591 30.3037 137.582 29.6364 132.723 30.5079C132.52 30.526 132.331 30.6222 132.197 30.7763C132.064 30.9304 131.994 31.1304 132.005 31.3343C132.015 31.5381 132.104 31.7301 132.253 31.8698C132.402 32.0096 132.599 32.0863 132.803 32.0837Z" fill="url(#paint5_linear)"/>
            <path d="M153.849 38.7945C154.419 39.0135 154.825 39.9582 155.135 40.4686C155.476 41.0867 155.967 41.6088 156.564 41.9863C157.143 42.3308 157.668 41.6993 157.563 41.1395C157.247 39.6591 156.086 37.3788 154.454 37.1039C153.503 36.9621 152.826 38.4203 153.849 38.7945Z" fill="url(#paint6_linear)"/>
            <path d="M160.324 49.0104C160.789 55.9529 158.235 60.561 154.309 66.0197C154.193 66.1395 154.125 66.2986 154.121 66.4657C154.116 66.6327 154.174 66.7955 154.284 66.9216C154.393 67.0477 154.546 67.1279 154.713 67.1464C154.879 67.165 155.046 67.1204 155.18 67.0215C160.474 64.3904 163.701 54.0982 161.673 48.7535C161.623 48.591 161.515 48.4526 161.369 48.3649C161.224 48.2772 161.051 48.2462 160.884 48.278C160.717 48.3099 160.567 48.4022 160.464 48.5373C160.361 48.6724 160.311 48.8409 160.324 49.0104Z" fill="url(#paint7_linear)"/>
            <path d="M150.284 71.0685C149.06 70.9182 146.942 70.9565 147.243 72.7583C147.324 73.315 148.065 73.7541 148.505 73.2114C148.944 72.6688 149.299 72.8119 149.89 72.8596C151.041 73.0009 151.456 71.2373 150.284 71.0685Z" fill="url(#paint8_linear)"/>
            <path d="M141.383 72.3125C140.474 72.7006 139.504 72.9264 138.517 72.9797C138.391 73.0194 138.278 73.0948 138.193 73.1968C138.108 73.2988 138.054 73.423 138.038 73.5548C138.022 73.6865 138.044 73.8202 138.102 73.9397C138.16 74.0592 138.251 74.1595 138.364 74.2286C138.996 74.5143 139.697 74.6125 140.383 74.5117C141.07 74.4109 141.713 74.1154 142.236 73.6602C142.371 73.53 142.457 73.3572 142.48 73.171C142.502 72.9847 142.46 72.7964 142.359 72.6379C142.259 72.4794 142.107 72.3605 141.929 72.3012C141.751 72.2419 141.558 72.2459 141.383 72.3125Z" fill="url(#paint9_linear)"/>
            <path d="M121.77 40.9707C120.795 45.3988 120.571 49.9591 121.108 54.4613C121.275 55.327 122.608 54.9936 122.582 54.1949C122.512 49.876 122.678 45.5565 123.077 41.2555C123.173 40.4719 121.869 40.1626 121.77 40.9707Z" fill="url(#paint10_linear)"/>
            <path d="M161.627 30.3042C151.265 20.7793 134.947 16.2403 122.942 25.5543C122.813 25.6373 122.723 25.7682 122.69 25.918C122.658 26.0679 122.686 26.2245 122.769 26.3534C122.853 26.4823 122.983 26.5728 123.133 26.6052C123.283 26.6376 123.44 26.6091 123.568 26.5261C136.637 19.6793 149.654 21.7499 160.858 31.1046C160.968 31.1733 161.099 31.202 161.228 31.1859C161.357 31.1698 161.476 31.1099 161.566 31.0161C161.657 30.9223 161.712 30.8005 161.723 30.6709C161.734 30.5413 161.7 30.4118 161.627 30.3042Z" fill="url(#paint11_linear)"/>
            <path d="M169.938 44.3991C172.334 54.6361 168.858 63.1081 161.055 69.7811C160.502 70.2352 160.857 71.3974 161.67 71.0498C171.525 66.6918 174.479 53.3584 171.546 43.8509C171.234 42.7438 169.7 43.301 169.938 44.3991Z" fill="url(#paint12_linear)"/>
            <path d="M85.2844 44.2211C73.2232 49.9487 61.1531 55.7497 49.5004 62.1986C48.8617 62.5428 49.4755 63.4136 50.0837 63.1155C61.9301 57.1129 73.6786 51.0984 85.6538 45.2608C86.308 44.9931 85.9476 43.88 85.2844 44.2211Z" fill="url(#paint13_linear)"/>
            <path d="M65.6687 79.4953C52.257 87.5179 38.5593 94.8343 25.2182 102.89C24.5764 103.259 25.2117 104.157 25.826 103.81C39.5696 96.5243 52.9598 88.4743 66.1906 80.3052C66.2695 80.2253 66.3189 80.1209 66.3305 80.0092C66.3422 79.8975 66.3153 79.7851 66.2545 79.6907C66.1937 79.5964 66.1025 79.5256 65.9959 79.4901C65.8894 79.4545 65.774 79.4563 65.6687 79.4953Z" fill="url(#paint14_linear)"/>
            <path d="M55.3652 104.505C43.7302 112.024 32.5436 120.345 21.3076 128.461C21.182 128.544 21.0947 128.674 21.0649 128.822C21.0351 128.97 21.0652 129.124 21.1487 129.249C21.2321 129.375 21.362 129.462 21.5099 129.492C21.6577 129.522 21.8113 129.492 21.9369 129.408C33.7592 122.185 44.375 113.048 55.9056 105.366C55.9935 105.284 56.05 105.174 56.0655 105.055C56.0811 104.935 56.0546 104.815 55.9907 104.713C55.9268 104.611 55.8294 104.534 55.7153 104.496C55.6012 104.458 55.4774 104.461 55.3652 104.505Z" fill="url(#paint15_linear)"/>
            <path d="M76.2062 99.532C65.8727 107.585 54.8923 115.036 45.0158 123.617C44.5425 124.031 45.2203 124.785 45.7336 124.45C56.4417 116.991 66.597 108.568 76.851 100.556C77.3489 100.145 76.811 99.0594 76.2062 99.532Z" fill="url(#paint16_linear)"/>
            <path d="M122.879 100.763C103.545 112.483 86.1734 127.054 67.365 139.56C67.2362 139.643 67.1456 139.774 67.1132 139.924C67.0808 140.073 67.1093 140.23 67.1924 140.359C67.2754 140.488 67.4063 140.578 67.5561 140.611C67.7059 140.643 67.8625 140.615 67.9913 140.531C87.0944 128.46 104.19 113.507 123.444 101.628C124.018 101.202 123.45 100.361 122.879 100.763Z" fill="url(#paint17_linear)"/>
            <path d="M131.144 27.978C128.337 46.798 136.036 65.8642 149.594 78.9134C150.53 79.7988 151.775 78.3608 150.919 77.4355C138.229 63.7968 130.412 47.3014 132.791 28.3294C132.921 27.2764 131.326 26.9066 131.144 27.978Z" fill="url(#paint18_linear)"/>
            <path d="M136.483 27.6145C133.36 43.935 140.588 64.211 152.735 75.3966C153.471 76.0835 154.766 75.0494 154.097 74.2216C142.613 59.6622 138.252 46.201 137.811 27.7279C137.826 27.5517 137.77 27.3768 137.656 27.2417C137.542 27.1065 137.38 27.0221 137.203 27.007C137.027 26.992 136.852 27.0475 136.717 27.1615C136.582 27.2754 136.498 27.4384 136.483 27.6145Z" fill="url(#paint19_linear)"/>
            <path d="M114.293 39.7295C96.5195 48.4594 79.4477 58.9658 62.1324 68.622C61.1178 69.1934 62.1246 70.709 63.1147 70.1346C80.3841 60.4479 98.2943 51.4116 115.005 40.8112C115.732 40.3537 115.032 39.3729 114.293 39.7295Z" fill="url(#paint20_linear)"/>
            <path d="M111.435 51.0615C94.8222 57.2244 78.2521 63.4424 61.8878 70.0088C60.8943 70.4088 61.3964 71.9867 62.4144 71.5897C78.9721 65.2708 95.4468 58.8174 111.98 52.4954C112.796 52.1234 112.349 50.7014 111.435 51.0615Z" fill="url(#paint21_linear)"/>
            <path d="M60.0005 76.0658C58.9496 75.9368 57.9607 75.499 57.1587 74.8078C56.3566 74.1166 55.7775 73.2031 55.4946 72.1827C55.2117 71.1624 55.2377 70.0811 55.5692 69.0754C55.9008 68.0698 56.5231 67.1851 57.3574 66.5332C58.1917 65.8813 59.2005 65.4914 60.2563 65.4129C61.3121 65.3344 62.3675 65.5708 63.2889 66.0922C64.2103 66.6136 64.9565 67.3966 65.433 68.3421C65.9095 69.2877 66.095 70.3533 65.966 71.4043C65.7929 72.8136 65.0672 74.0965 63.9484 74.9707C62.8297 75.8449 61.4096 76.2388 60.0005 76.0658Z" fill="url(#paint22_linear)"/>
            <path d="M112.733 64.1709C95.903 74.5328 80.0008 86.8482 63.8182 98.2095C62.8681 98.8634 63.8504 100.376 64.8036 99.6975C81.1116 88.3269 97.9197 77.5396 113.47 65.2556C114.007 64.725 113.461 63.7133 112.733 64.1709Z" fill="url(#paint23_linear)"/>
            <path d="M117.171 72.1486C99.1233 81.0934 81.235 90.356 63.3836 99.7227C62.43 100.202 63.354 101.583 64.3076 101.104C82.2943 92.0521 99.9983 82.4686 117.988 73.3924C118.865 72.9284 118.116 71.7426 117.171 72.1486Z" fill="url(#paint24_linear)"/>
            <path d="M62.3688 105.762C61.3096 105.745 60.2794 105.413 59.4087 104.81C58.538 104.206 57.8659 103.358 57.4776 102.372C57.0892 101.386 57.0022 100.307 57.2273 99.2718C57.4524 98.2365 57.9796 97.2911 58.7422 96.5556C59.5048 95.8201 60.4685 95.3274 61.5112 95.14C62.5539 94.9525 63.6287 95.0788 64.5996 95.5026C65.5705 95.9265 66.3938 96.629 66.9653 97.5212C67.5368 98.4134 67.8307 99.455 67.8099 100.514C67.7756 101.929 67.1852 103.273 66.1667 104.256C65.1482 105.238 63.7837 105.779 62.3688 105.762Z" fill="url(#paint25_linear)"/>
            <path d="M136.168 81.7888C118.635 90.9955 101.873 102.012 84.8466 112.151C83.8565 112.726 84.8634 114.241 85.8565 113.642C102.837 103.473 120.462 93.9292 136.856 82.8675C136.986 82.7705 137.074 82.6282 137.103 82.4689C137.132 82.3097 137.1 82.1454 137.013 82.0089C136.926 81.8725 136.79 81.7739 136.634 81.7331C136.477 81.6923 136.311 81.7121 136.168 81.7888Z" fill="url(#paint26_linear)"/>
            <path d="M124.728 78.6196C110.929 89.8011 97.1441 100.86 83.3446 112.042C82.5413 112.714 83.4133 114.113 84.2655 113.447C98.1664 102.452 111.856 91.1577 125.471 79.8542C126.222 79.2007 125.433 77.9356 124.728 78.6196Z" fill="url(#paint27_linear)"/>
            <path d="M78.42 111.661C78.0138 112.632 77.9041 113.701 78.1045 114.734C78.305 115.767 78.8068 116.717 79.5466 117.465C80.2864 118.213 81.2311 118.726 82.2616 118.938C83.2922 119.15 84.3624 119.052 85.3374 118.656C86.3124 118.261 87.1485 117.586 87.7404 116.716C88.3322 115.846 88.6533 114.82 88.6632 113.768C88.6731 112.716 88.3713 111.684 87.7958 110.803C87.2204 109.922 86.3971 109.231 85.4297 108.818C84.1231 108.266 82.6511 108.256 81.3368 108.789C80.0226 109.322 78.9735 110.355 78.42 111.661Z" fill="url(#paint28_linear)"/>
            <path d="M106.776 44.4739C106.235 45.8252 106.088 47.3023 106.352 48.7338C106.616 50.1653 107.281 51.4926 108.269 52.5616C108.913 53.1875 109.865 52.3102 109.432 51.5859C108.743 50.6264 108.227 49.5535 107.908 48.4159C107.718 47.2559 107.737 46.0713 107.965 44.918C108.119 44.2659 107.09 43.7418 106.776 44.4739Z" fill="url(#paint29_linear)"/>
            <path d="M111.346 42.6738C110.169 43.1641 109.05 43.7855 108.012 44.5264C108.281 44.5595 108.544 44.4427 108.826 44.3778L108.532 44.3417L109.379 45.3405L109.4 45.169L109.129 45.9561L109.147 45.8092L107.85 46.0477L107.267 45.1309C107.222 46.9151 107.316 52.2212 110.219 51.8568C110.428 51.846 110.624 51.7527 110.764 51.5975C110.904 51.4423 110.977 51.2377 110.967 51.0288C110.915 50.0283 110.073 49.4028 109.798 48.3997C109.512 47.2726 109.387 46.1108 109.427 44.9486C109.414 44.7592 109.354 44.5761 109.252 44.4159C109.15 44.2557 109.009 44.1237 108.843 44.0319L108.721 44.0169C108.507 43.9166 108.267 43.8856 108.034 43.9284C107.802 43.9711 107.589 44.0855 107.424 44.2554C107.252 44.435 107.131 44.6579 107.075 44.9004C107.019 45.1429 107.03 45.3961 107.106 45.6331C107.162 45.8108 107.263 45.9707 107.401 46.0966C107.538 46.2226 107.706 46.3102 107.888 46.3506C109.479 46.546 111.278 44.8528 112.076 43.6086C112.632 43.13 111.909 42.5441 111.346 42.6738Z" fill="url(#paint30_linear)"/>
            <path d="M110.102 44.5095C109.631 45.6134 109.36 46.7921 109.302 47.9907C109.172 49.0436 109.197 50.4635 110.083 51.144C110.18 51.2236 110.293 51.2811 110.414 51.3125C110.536 51.3438 110.663 51.3483 110.786 51.3256C110.91 51.3029 111.027 51.2536 111.129 51.181C111.232 51.1084 111.317 51.0144 111.379 50.9055C111.855 50.069 111.587 49.2158 111.524 48.3132C111.519 47.2412 111.601 46.1706 111.768 45.1118C111.906 44.1842 110.519 43.5416 110.102 44.5095Z" fill="url(#paint31_linear)"/>
            <path d="M108.261 46.5455C108.4 47.4457 108.678 48.3187 109.087 49.1326C109.538 49.9088 110.532 49.31 110.564 48.6428C110.539 47.6845 110.353 46.7373 110.013 45.8409C109.717 44.8103 107.98 45.3924 108.261 46.5455Z" fill="url(#paint32_linear)"/>
            <path d="M111.483 65.0367C110.547 66.1893 111.084 68.2935 111.677 69.5346C112.307 71.0462 113.433 72.2979 114.87 73.0835C115.079 73.2083 115.329 73.2486 115.567 73.1963C115.806 73.1441 116.015 73.0032 116.154 72.8021C116.292 72.6011 116.349 72.355 116.313 72.1136C116.276 71.8722 116.15 71.6536 115.958 71.5021C114.923 70.7281 114.092 69.7131 113.538 68.5452C113.077 67.4446 113.309 66.1558 112.763 65.144C112.695 65.0404 112.605 64.9536 112.498 64.8901C112.392 64.8267 112.273 64.7884 112.149 64.778C112.026 64.7677 111.902 64.7856 111.786 64.8304C111.671 64.8753 111.567 64.9458 111.483 65.0367Z" fill="url(#paint33_linear)"/>
            <path d="M112.116 65.9597C112.665 68.6398 114.043 71.079 116.056 72.9313C116.291 73.1414 116.599 73.2496 116.914 73.2323C117.228 73.2149 117.523 73.0734 117.733 72.8388C117.943 72.6042 118.051 72.2958 118.034 71.9813C118.017 71.6669 117.875 71.3722 117.641 71.1621C115.939 69.5598 114.617 67.597 113.773 65.4175C113.737 65.3087 113.681 65.2079 113.606 65.121C113.532 65.0341 113.441 64.9627 113.339 64.911C113.236 64.8592 113.125 64.828 113.011 64.8192C112.897 64.8105 112.782 64.8243 112.673 64.8599C112.564 64.8955 112.464 64.9522 112.377 65.0267C112.29 65.1013 112.219 65.1922 112.167 65.2944C112.115 65.3965 112.084 65.5078 112.075 65.622C112.066 65.7361 112.08 65.8509 112.116 65.9597Z" fill="url(#paint34_linear)"/>
            <path d="M123.165 80.4158C123.077 83.3629 126.167 86.1286 128.595 87.1974C129.778 87.6905 130.67 85.8862 129.506 85.445C128.28 84.9931 127.185 84.2437 126.32 83.264C125.606 82.2328 124.983 81.1417 124.458 80.0029C124.065 79.3581 123.194 79.7731 123.165 80.4158Z" fill="url(#paint35_linear)"/>
            <path d="M124.676 81.2722C125.382 83.1764 126.748 84.764 128.525 85.7468C129.65 86.5063 130.711 84.9464 130.137 83.9561C129.417 82.7279 128.615 81.5498 127.736 80.43L125.788 82.1296C126.511 82.8462 127.307 83.4855 128.163 84.037C129.355 84.8545 130.508 83.1566 129.891 82.1114C129.192 81.0081 128.376 79.983 127.458 79.0536L125.835 81.34C128.206 82.2773 130.459 83.1506 132.787 84.0329L133.12 81.3148C131.934 81.5304 130.812 82.0131 129.84 82.7266C129.642 82.8771 129.484 83.0744 129.381 83.3007C129.278 83.5271 129.233 83.7755 129.249 84.0238C129.265 84.2721 129.343 84.5125 129.475 84.7234C129.607 84.9343 129.789 85.1092 130.005 85.2325C130.431 85.4852 130.919 85.6163 131.415 85.6114C131.91 85.6065 132.395 85.4659 132.817 85.2049C134.275 84.4642 133.153 82.4623 131.936 82.4619C131.326 82.4126 130.712 82.4922 130.135 82.6954C129.558 82.8986 129.03 83.2208 128.585 83.6413C127.519 84.629 128.823 86.1562 130.028 85.8567C130.691 85.615 131.387 85.4742 132.092 85.4391L131.187 82.6931L131.376 82.5672L131.516 85.0701C132.297 84.5222 133.192 84.1564 134.133 83.9994C134.408 83.9125 134.652 83.7483 134.837 83.5263C135.021 83.3044 135.138 83.034 135.173 82.7476C135.209 82.4611 135.161 82.1706 135.035 81.9106C134.91 81.6506 134.713 81.4321 134.467 81.2812C132.093 80.3684 129.573 79.4375 127.12 78.5646C126.813 78.4976 126.492 78.5338 126.207 78.6676C125.923 78.8014 125.69 79.0253 125.545 79.3048C125.4 79.5842 125.352 79.9036 125.407 80.2134C125.462 80.5233 125.618 80.8063 125.85 81.0187C126.733 81.6663 127.478 82.4846 128.04 83.4252L129.324 81.6689C128.66 81.2307 128.039 80.73 127.47 80.1735C126.449 79.1782 124.57 80.7124 125.521 81.8731C126.433 82.9645 127.263 84.1216 128.004 85.3349L129.616 83.5441C128.351 82.5721 127.169 81.4958 126.084 80.3265C125.933 80.2231 125.755 80.1674 125.572 80.1665C125.39 80.1655 125.211 80.2195 125.059 80.3214C124.907 80.4232 124.79 80.5683 124.721 80.7378C124.653 80.9074 124.637 81.0935 124.676 81.2722Z" fill="url(#paint36_linear)"/>
            <path d="M110.02 67.6408C109.954 69.0082 110.262 70.3679 110.911 71.5733C111.56 72.7786 112.526 73.7841 113.704 74.4813C114.718 75.1278 115.595 73.4459 114.557 72.7963C113.676 72.1846 112.938 71.3886 112.395 70.4636C111.852 69.5385 111.517 68.5064 111.412 67.4388C111.258 66.873 110.138 66.8847 110.02 67.6408Z" fill="url(#paint37_linear)"/>
            <defs>
            <linearGradient id="paint0_linear" x1="10.8278" y1="138.539" x2="0.763845" y2="137.304" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint1_linear" x1="64.5757" y1="7.68105" x2="58.6255" y2="6.95046" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint2_linear" x1="45.7144" y1="24.6174" x2="38.7709" y2="23.7648" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint3_linear" x1="167.84" y1="48.0221" x2="112.957" y2="41.2833" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint4_linear" x1="139.085" y1="52.9504" x2="109.885" y2="49.3652" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint5_linear" x1="147.509" y1="32.1384" x2="131.645" y2="30.1906" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint6_linear" x1="157.853" y1="39.0826" x2="153.139" y2="38.5038" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint7_linear" x1="162.118" y1="54.9495" x2="155.427" y2="54.1279" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint8_linear" x1="150.968" y1="72.0437" x2="147.19" y2="71.5798" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint9_linear" x1="142.476" y1="73.2011" x2="138.012" y2="72.6529" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint10_linear" x1="123.657" y1="45.5076" x2="120.989" y2="45.18" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint11_linear" x1="162.303" y1="25.9494" x2="122.053" y2="21.0073" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint12_linear" x1="172.609" y1="53.14" x2="162.744" y2="51.9287" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint13_linear" x1="85.1232" y1="51.9075" x2="49.989" y2="47.5935" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint14_linear" x1="65.205" y1="89.1763" x2="26.0685" y2="84.3709" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint15_linear" x1="54.9515" y1="114.128" x2="22.3562" y2="110.126" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint16_linear" x1="75.9616" y1="108.991" x2="46.2229" y2="105.339" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint17_linear" x1="121.857" y1="116.087" x2="69.2119" y2="109.623" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint18_linear" x1="155.071" y1="46.7137" x2="128.408" y2="43.4398" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint19_linear" x1="157.925" y1="45.099" x2="133.871" y2="42.1456" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint20_linear" x1="113.894" y1="51.8925" x2="63.0434" y2="45.6489" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint21_linear" x1="111.423" y1="59.8797" x2="61.9066" y2="53.7999" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint22_linear" x1="66.1834" y1="69.633" x2="55.2253" y2="68.2875" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint23_linear" x1="112.08" y1="77.8643" x2="65.2754" y2="72.1174" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint24_linear" x1="117.02" y1="83.9058" x2="64.1636" y2="77.4158" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint25_linear" x1="67.9862" y1="99.2901" x2="57.0304" y2="97.9449" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint26_linear" x1="135.64" y1="94.3892" x2="85.9167" y2="88.2839" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint27_linear" x1="124.243" y1="91.7357" x2="84.9798" y2="86.9148" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint28_linear" x1="88.8397" y1="112.608" x2="77.9321" y2="111.269" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint29_linear" x1="110.122" y1="47.2911" x2="106.109" y2="46.7984" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint30_linear" x1="111.958" y1="45.8486" x2="106.872" y2="45.2241" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint31_linear" x1="112.065" y1="46.6262" x2="109.4" y2="46.2991" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint32_linear" x1="110.784" y1="46.8687" x2="108.112" y2="46.5407" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint33_linear" x1="116.848" y1="68.0391" x2="110.688" y2="67.2826" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint34_linear" x1="118.524" y1="68.1463" x2="111.656" y2="67.3032" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint35_linear" x1="130.473" y1="82.8096" x2="122.748" y2="81.8611" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint36_linear" x1="135.272" y1="81.9427" x2="124.371" y2="80.6042" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            <linearGradient id="paint37_linear" x1="115.476" y1="70.0049" x2="109.639" y2="69.2883" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0CE2E2"/>
            <stop offset="1" stop-color="#27AAE1"/>
            </linearGradient>
            </defs>
            </svg>

    )
}