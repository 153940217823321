

export const FireSatellite = () => {

    return (
        <svg width="146" height="60" viewBox="0 0 146 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M144.43 20.765L144.683 20.5517C144.942 20.3144 145.148 20.0269 145.289 19.7073C145.429 19.3877 145.501 19.0429 145.5 18.6946C145.499 18.3463 145.424 18.0021 145.281 17.6835C145.138 17.3649 144.93 17.0789 144.669 16.8435C144.409 16.6082 144.101 16.4284 143.767 16.3157C143.432 16.203 143.078 16.1597 142.725 16.1885C142.373 16.2174 142.03 16.3178 141.719 16.4834C141.408 16.649 141.135 16.8762 140.918 17.1508C140.659 17.5079 140.471 17.9099 140.364 18.3357C140.245 18.8769 140.304 19.4414 140.533 19.9473C140.28 19.3667 140.533 19.9473 140.641 20.3028C140.837 20.7005 141.159 21.025 141.558 21.2279C141.957 21.4308 142.412 21.5012 142.854 21.4285C143.071 21.4285 143.3 21.2508 143.516 21.2271L143.227 21.3219C143.561 21.278 143.877 21.1471 144.142 20.9427C144.429 20.7581 144.681 20.5255 144.888 20.2554C145.068 20.0184 144.418 20.7057 144.683 20.5043L142.782 21.0849C143.064 21.1595 143.358 21.1695 143.644 21.114C143.93 21.0585 144.199 20.9391 144.43 20.765Z" fill="#E31863"/>
        <path d="M46.3847 54.0867L46.6373 53.8615C46.9021 53.6268 47.1141 53.3399 47.2595 53.0196C47.4049 52.6992 47.4806 52.3523 47.4816 52.0013C47.4826 51.6504 47.409 51.3031 47.2654 50.9819C47.1218 50.6607 46.9115 50.3727 46.648 50.1364C46.3845 49.9002 46.0737 49.721 45.7356 49.6103C45.3975 49.4996 45.0396 49.4599 44.6849 49.4938C44.3303 49.5277 43.9868 49.6344 43.6766 49.8071C43.3664 49.9797 43.0963 50.2145 42.8839 50.4962C42.6163 50.8481 42.4277 51.252 42.3305 51.6812C42.2016 52.2168 42.2565 52.7794 42.4869 53.2809C42.2342 52.7003 42.4869 53.2809 42.5952 53.6364C42.7899 54.0354 43.1113 54.3611 43.5107 54.5642C43.91 54.7673 44.3657 54.8368 44.8087 54.7621C45.0282 54.6826 45.2534 54.6192 45.4824 54.5725L45.1937 54.6555C45.5251 54.6114 45.8397 54.4849 46.108 54.2881C46.3927 54.1003 46.6446 53.8682 46.8538 53.6009C47.0343 53.3639 46.3847 54.0393 46.6373 53.8378L44.7365 54.4303C45.0195 54.5026 45.3156 54.5092 45.6016 54.4495C45.8876 54.3899 46.1557 54.2657 46.3847 54.0867Z" fill="#E31863"/>
        <path d="M119.997 57.7246C119.769 55.0939 115.486 55.7338 115.955 58.3526C116.424 60.9714 120.226 60.2486 119.997 57.7246Z" fill="#E31863"/>
        <path d="M122.716 53.1979C122.967 53.0814 123.201 52.9341 123.414 52.7594C123.535 52.6543 123.631 52.5236 123.695 52.3772C123.758 52.2307 123.788 52.0721 123.781 51.913C123.774 51.7538 123.731 51.5983 123.655 51.4577C123.579 51.3171 123.472 51.195 123.342 51.1005C123.112 50.9253 122.862 50.7781 122.596 50.662C121.694 50.1999 120.695 51.136 120.731 52.0129C120.741 52.2388 120.808 52.4588 120.925 52.6533C121.042 52.8478 121.206 53.0109 121.402 53.1281C121.598 53.2453 121.821 53.313 122.05 53.3252C122.279 53.3373 122.508 53.2936 122.716 53.1979Z" fill="#E31863"/>
        <path d="M98.776 52.5225C98.616 52.4877 98.4591 52.4401 98.3068 52.3803L99.161 52.9728C99.0243 52.8265 98.8754 52.6917 98.7159 52.5699C98.3927 52.352 98.0143 52.2273 97.6232 52.2096C97.232 52.1919 96.8436 52.282 96.5016 52.4698C96.1596 52.6576 95.8775 52.9357 95.6871 53.2727C95.4967 53.6097 95.4054 53.9924 95.4237 54.3776C95.4419 54.7629 95.5688 55.1356 95.7903 55.4537C96.0117 55.7718 96.3188 56.0228 96.677 56.1785C97.0353 56.3341 97.4305 56.3883 97.8183 56.3348C98.2061 56.2814 98.5711 56.1225 98.8722 55.876C98.8722 55.876 97.8737 56.196 98.2587 56.1723C98.3224 56.1831 98.3875 56.1831 98.4512 56.1723C98.8577 56.0923 99.228 55.8876 99.509 55.5874C99.79 55.2872 99.9672 54.907 100.015 54.5014C100.061 54.0829 99.964 53.6612 99.7397 53.3029C99.5153 52.9446 99.1763 52.67 98.776 52.5225Z" fill="#E31863"/>
        <path d="M92.364 17.5655C93.4828 15.8354 95.2873 14.2712 97.5249 14.8874C99.7626 15.5036 100.448 17.8262 100.689 19.9117C100.689 20.0689 100.752 20.2196 100.865 20.3307C100.978 20.4418 101.131 20.5042 101.29 20.5042C101.45 20.5042 101.603 20.4418 101.716 20.3307C101.829 20.2196 101.892 20.0689 101.892 19.9117C102.337 13.7854 94.2648 11.4984 91.8106 17.3048C91.8093 17.3685 91.8267 17.4312 91.8606 17.4855C91.8946 17.5397 91.9437 17.5832 92.002 17.6107C92.0604 17.6382 92.1256 17.6486 92.1898 17.6405C92.254 17.6325 92.3144 17.6065 92.364 17.5655Z" fill="#E31863"/>
        <path d="M101.014 20.919C101.264 20.6709 101.588 20.5083 101.939 20.455C102.289 20.4016 102.648 20.4604 102.962 20.6227C103.636 21.0967 103.744 22.0092 104.466 22.4002C104.517 22.4324 104.574 22.4532 104.634 22.4609C104.694 22.4687 104.755 22.4634 104.812 22.4452C104.87 22.4271 104.923 22.3967 104.967 22.3561C105.011 22.3155 105.045 22.2659 105.068 22.2106C105.477 21.1204 104.322 19.7695 103.372 19.3074C102.84 19.0813 102.25 19.0272 101.686 19.1528C101.122 19.2785 100.612 19.5774 100.232 20.0065C100.171 20.0571 100.121 20.119 100.084 20.1886C100.048 20.2583 100.025 20.3344 100.019 20.4125C100.012 20.4906 100.021 20.5693 100.045 20.644C100.069 20.7187 100.108 20.788 100.159 20.8479C100.211 20.9078 100.274 20.9572 100.344 20.9932C100.415 21.0291 100.492 21.0511 100.572 21.0577C100.651 21.0643 100.731 21.0554 100.807 21.0316C100.882 21.0078 100.953 20.9696 101.014 20.919Z" fill="#E31863"/>
        <path d="M101.94 18.1461C102.937 17.6974 104.02 17.467 105.116 17.4706C106.472 17.7327 107.691 18.4589 108.557 19.5206C109.429 20.5178 109.956 21.7632 110.06 23.0756C110.133 24.5094 109.218 25.4456 109.026 26.7964C109.019 26.8726 109.032 26.9491 109.065 27.0185C109.098 27.0879 109.148 27.1478 109.211 27.1921C109.274 27.2364 109.348 27.2637 109.425 27.2712C109.503 27.2787 109.581 27.2662 109.651 27.2349C111.071 26.5357 111.456 24.3554 111.36 22.9334C111.213 21.2981 110.514 19.7585 109.375 18.5608C107.787 16.8663 103.227 14.7452 101.651 17.6602C101.555 17.9091 101.723 18.2172 101.94 18.1461Z" fill="#E31863"/>
        <path d="M108.28 25.9433C108.376 26.3107 108.521 26.678 108.593 27.069C108.665 27.4601 108.593 27.6852 108.593 27.9815C108.603 28.0369 108.627 28.0888 108.664 28.1319C108.701 28.1751 108.748 28.2079 108.802 28.2271C108.856 28.2464 108.914 28.2513 108.97 28.2415C109.026 28.2316 109.079 28.2074 109.123 28.1711C109.845 27.6971 109.231 26.2988 108.978 25.7063C108.858 25.3034 108.147 25.5167 108.28 25.9433Z" fill="#E31863"/>
        <path d="M110.229 26.0618C111.113 26.4378 111.868 27.0571 112.403 27.8453C112.939 28.6335 113.232 29.5571 113.248 30.5055C113.152 32.8754 111.023 33.622 108.954 33.7879C108.292 33.7879 108.34 34.9729 108.954 34.9729C111.576 34.9729 114.151 33.5983 114.355 30.7899C114.422 29.5887 114.071 28.4009 113.361 27.4228C112.65 26.4446 111.621 25.7346 110.445 25.41C110.37 25.4138 110.298 25.44 110.238 25.4851C110.178 25.5302 110.133 25.5921 110.11 25.6626C110.086 25.7332 110.085 25.8091 110.106 25.8804C110.128 25.9517 110.17 26.0149 110.229 26.0618Z" fill="#E31863"/>
        <path d="M108.436 32.7689C109.074 34.8426 109.146 36.8215 107.017 38.0539C106.087 38.5359 105.037 38.7484 103.989 38.6666C102.942 38.5848 101.939 38.2121 101.098 37.5917C100.967 37.5117 100.81 37.4842 100.659 37.515C100.508 37.5457 100.375 37.6323 100.287 37.757C100.199 37.8816 100.163 38.0348 100.186 38.1849C100.209 38.3349 100.29 38.4704 100.412 38.5634C101.469 39.4073 102.773 39.8959 104.132 39.9578C105.492 40.0196 106.835 39.6514 107.967 38.9071C108.49 38.5658 108.939 38.1254 109.287 37.6115C109.636 37.0977 109.877 36.5206 109.997 35.914C110.117 35.3074 110.113 34.6834 109.985 34.0783C109.858 33.4732 109.609 32.8992 109.254 32.3897C109.187 32.3131 109.097 32.2603 108.997 32.2392C108.896 32.2182 108.792 32.23 108.699 32.2731C108.606 32.3161 108.53 32.3878 108.483 32.4774C108.435 32.5669 108.419 32.6693 108.436 32.7689Z" fill="#E31863"/>
        <path d="M103.793 38.9189C103.119 41.834 101.314 43.2086 98.1745 42.782C96.6269 42.6041 95.1781 41.9417 94.0411 40.8922C92.9042 39.8427 92.1392 38.4615 91.8587 36.9518C91.8284 36.7837 91.7315 36.6343 91.5894 36.5365C91.4473 36.4388 91.2715 36.4006 91.1008 36.4305C90.9301 36.4603 90.7784 36.5557 90.6792 36.6957C90.5799 36.8357 90.5412 37.0089 90.5715 37.177C91.0286 41.0756 94.6617 43.7892 98.4994 44.0854C101.495 44.2987 105.333 42.5213 104.514 38.99C104.49 38.6464 103.889 38.4805 103.793 38.9189Z" fill="#E31863"/>
        <path d="M92.6888 15.634C91.9121 15.1459 90.9877 14.9381 90.0729 15.0462C89.1582 15.1542 88.3097 15.5713 87.6722 16.2265C86.4692 17.3404 85.1459 20.1843 86.6015 21.5826C86.6577 21.6417 86.7297 21.6839 86.8093 21.7042C86.8888 21.7245 86.9726 21.7221 87.0508 21.6972C87.129 21.6724 87.1984 21.6261 87.2509 21.5638C87.3035 21.5016 87.3369 21.4259 87.3474 21.3456C87.4917 20.6109 87.179 19.8881 87.3474 19.1297C87.5157 18.3711 87.9143 17.6809 88.4903 17.1508C88.9897 16.6609 89.6238 16.3258 90.3143 16.1868C91.0048 16.0479 91.7216 16.1111 92.376 16.3687C92.4749 16.4095 92.5863 16.41 92.6855 16.37C92.7848 16.33 92.8639 16.2528 92.9054 16.1554C92.9468 16.058 92.9473 15.9483 92.9067 15.8505C92.8661 15.7527 92.7877 15.6748 92.6888 15.634Z" fill="#E31863"/>
        <path d="M89.3083 19.9947C86.012 19.6037 83.5218 22.4476 83.4736 25.5404C83.4736 28.2185 86.3368 32.2474 89.4887 30.7899C89.557 30.7576 89.6155 30.7083 89.6584 30.6468C89.7012 30.5854 89.727 30.5139 89.7331 30.4396C89.7392 30.3652 89.7253 30.2906 89.693 30.2232C89.6607 30.1557 89.611 30.0978 89.5489 30.0552C88.3459 29.4272 87.0346 29.5931 85.9398 28.6095C85.5381 28.198 85.2245 27.7111 85.0178 27.1777C84.8111 26.6443 84.7156 26.0754 84.7368 25.5049C84.7286 24.9093 84.8421 24.3182 85.0705 23.7667C85.2988 23.2152 85.6374 22.7145 86.0661 22.2944C86.4948 21.8744 87.0049 21.5435 87.566 21.3215C88.1271 21.0994 88.7278 20.9908 89.3323 21.0019C89.9459 20.9782 89.8737 20.0658 89.3083 19.9947Z" fill="#E31863"/>
        <path d="M87.4195 30.1856C86.5513 30.6394 85.8885 31.3986 85.563 32.3121C85.2375 33.2256 85.2733 34.2264 85.6631 35.1151C86.1512 36.1824 86.9187 37.1027 87.887 37.7816C88.8552 38.4604 89.9893 38.8734 91.173 38.9781C92.0512 39.0729 92.1233 37.7932 91.2933 37.651C90.4123 37.549 89.5665 37.2506 88.8202 36.7783C88.0739 36.306 87.4468 35.6724 86.9865 34.9255C86.1323 33.2784 86.8541 32.1052 87.9008 30.8136C88.1534 30.47 87.7564 30.0671 87.4195 30.1856Z" fill="#E31863"/>
        <path d="M92.7609 20.6109C92.0526 20.8612 91.4497 21.3379 91.0485 21.9651C90.6473 22.5922 90.4709 23.3336 90.5473 24.071C90.6692 24.8373 91.0319 25.5466 91.5843 26.0993C92.1367 26.6519 92.8511 27.0201 93.6271 27.152C93.718 27.1555 93.8079 27.1307 93.8837 27.081C93.9595 27.0314 94.0176 26.9595 94.0495 26.8755C94.0815 26.7915 94.0858 26.6998 94.0617 26.6132C94.0377 26.5267 93.9867 26.4499 93.9158 26.3936C93.0496 25.6826 91.991 25.3271 91.6301 24.1658C91.5041 23.5962 91.5814 23.0012 91.8487 22.481C92.116 21.9608 92.5571 21.5473 93.0977 21.31C93.1771 21.2579 93.2347 21.1794 93.2601 21.0889C93.2855 20.9984 93.277 20.9019 93.2361 20.817C93.1953 20.7322 93.1247 20.6646 93.0375 20.6268C92.9502 20.5889 92.852 20.5833 92.7609 20.6109Z" fill="#E31863"/>
        <path d="M90.5835 28.3014C90.2161 28.5827 89.9342 28.9581 89.7687 29.3865C89.6032 29.8149 89.5605 30.2799 89.6452 30.7306C89.7174 31.2113 89.9045 31.6682 90.1911 32.0638C90.4776 32.4593 90.8553 32.7822 91.2933 33.0058C91.57 33.1243 91.8226 32.7925 91.7144 32.5437C91.4377 31.9275 90.9444 31.4653 90.7159 30.8373C90.6044 30.4892 90.5834 30.1191 90.6549 29.7609C90.7263 29.4027 90.888 29.0679 91.1249 28.7873C91.1701 28.7144 91.1881 28.6284 91.1758 28.5439C91.1636 28.4594 91.1218 28.3817 91.0578 28.3243C90.9937 28.2668 90.9114 28.2331 90.8248 28.2289C90.7383 28.2247 90.653 28.2504 90.5835 28.3014Z" fill="#E31863"/>
        <path d="M102.589 27.152C102.881 27.6802 103.026 28.2745 103.011 28.8754C102.997 29.4763 102.822 30.0629 102.505 30.5766C101.711 31.6787 100.605 31.6194 99.4616 32.0934C99.3918 32.1223 99.3321 32.1709 99.2902 32.2331C99.2482 32.2953 99.2258 32.3683 99.2258 32.443C99.2258 32.5176 99.2482 32.5906 99.2902 32.6528C99.3321 32.715 99.3918 32.7636 99.4616 32.7926C100.917 33.5154 102.806 32.2356 103.54 31.0151C103.964 30.3632 104.161 29.5926 104.101 28.8205C104.04 28.0483 103.725 27.3168 103.203 26.7373C103.137 26.6914 103.058 26.6665 102.977 26.6661C102.896 26.6656 102.817 26.6896 102.75 26.7347C102.683 26.7798 102.632 26.844 102.603 26.9186C102.575 26.9932 102.57 27.0746 102.589 27.152Z" fill="#E31863"/>
        <path d="M93.8678 30.9084C93.9885 31.482 94.3126 31.9945 94.7821 32.3541C95.2152 32.674 95.8528 32.994 96.334 32.6266C96.3974 32.5588 96.4326 32.47 96.4326 32.3778C96.4326 32.2856 96.3974 32.1968 96.334 32.1289C96.1054 31.809 95.6844 31.809 95.3716 31.5957C95.029 31.3601 94.7485 31.0473 94.5535 30.6833C94.4092 30.3278 93.7595 30.4818 93.8678 30.9084Z" fill="#E31863"/>
        <path d="M93.8316 36.9518C93.9145 37.3636 94.0981 37.7494 94.3664 38.0756C94.6347 38.4019 94.9797 38.6588 95.3715 38.8241C95.7821 39.025 96.2312 39.1384 96.6893 39.1567C97.1474 39.1751 97.6043 39.0981 98.0302 38.9307C98.0899 38.9067 98.1411 38.8657 98.1771 38.813C98.2131 38.7602 98.2323 38.6981 98.2323 38.6345C98.2323 38.5709 98.2131 38.5088 98.1771 38.456C98.1411 38.4033 98.0899 38.3622 98.0302 38.3382C97.3926 38.1368 96.7068 38.2079 96.0692 38.0183C95.748 37.9103 95.4519 37.7404 95.1978 37.5187C94.9438 37.297 94.7371 37.0278 94.5895 36.7267C94.385 36.3475 93.6993 36.5252 93.8316 36.9518Z" fill="#E31863"/>
        <path d="M96.1534 23.7511C97.5248 23.0875 98.788 23.4548 100.244 23.4667C100.508 23.4667 100.749 23.0756 100.448 22.9216C99.7221 22.4774 98.8805 22.251 98.026 22.2699C97.1715 22.2888 96.3411 22.5523 95.6361 23.0282C95.2752 23.3126 95.7082 23.9644 96.1534 23.7511Z" fill="#E31863"/>
        <path d="M81.3443 8.40551C89.1158 9.0217 97.5249 8.8321 104.815 11.8894C113.128 15.4443 119.143 23.9288 117.242 32.9702C112.924 53.4467 87.4917 43.0663 75.2811 37.1058C74.9322 36.9399 74.6315 37.5087 74.9563 37.7101C87.2992 45.2585 113.862 54.2999 118.409 33.3139C119.949 26.204 116.821 19.0111 111.191 14.4489C102.926 7.67082 91.5339 8.38181 81.3924 7.88411C81.0556 7.87226 81.0075 8.38181 81.3443 8.40551Z" fill="#E31863"/>
        <path d="M80.4663 8.74919C82.011 9.52625 83.4984 10.4093 84.9174 11.3917C84.9717 11.4203 85.0342 11.4302 85.0949 11.4198C85.1556 11.4095 85.211 11.3795 85.2525 11.3346C85.2939 11.2897 85.3189 11.2325 85.3236 11.172C85.3283 11.1115 85.3123 11.0512 85.2783 11.0007C84.0042 9.69906 82.4446 8.70192 80.7189 8.0856C80.2858 7.88415 80.0693 8.54774 80.4663 8.74919Z" fill="#E31863"/>
        <path d="M84.5204 11.6051C78.9384 10.3016 73.3323 11.1074 67.6781 9.6854C62.8661 8.50042 58.5232 7.31543 53.663 8.74926C53.266 8.86776 53.3502 9.48395 53.7712 9.38915C59.7863 8.14492 64.6706 9.98164 70.397 11.214C75.0647 12.2213 79.7444 11.214 84.4241 12.0672C84.6768 12.1146 84.7851 11.6643 84.5204 11.6051Z" fill="#E31863"/>
        <path d="M53.6749 9.38903C55.8163 9.47198 62.9021 11.759 63.8765 14.2238C64.057 14.6741 64.7427 14.3541 64.5743 13.892L64.4299 13.5957C64.4095 13.4951 64.3507 13.4059 64.2658 13.3464C64.1808 13.287 64.076 13.2617 63.9728 13.2758C50.2463 15.0058 37.759 4.2225 24.1649 9.86302C23.8521 9.99337 24.0206 10.5385 24.3574 10.4437C32.8988 7.8604 40.1169 9.82747 48.4178 12.2804C49.705 12.6714 64.1292 15.0414 63.8525 14.2119C63.9848 14.6148 64.7187 14.3897 64.5863 13.9868C63.5999 10.9532 56.6344 8.70173 53.7592 8.73728C53.3261 8.76098 53.2539 9.37718 53.6749 9.38903Z" fill="#E31863"/>
        <path d="M24.8505 10.6452C34.4747 10.6452 41.0552 15.7051 47.8041 21.7366C53.338 26.6899 58.2102 26.9861 65.4403 27.7919C65.8614 27.7919 66.0418 27.1639 65.6088 27.0572C60.9771 25.8722 56.3455 26.4647 52.0868 23.8104C48.947 21.8551 46.6011 18.9519 43.7499 16.6412C38.4927 12.399 31.7197 9.4602 24.8265 10.1593C24.5618 10.183 24.5738 10.6452 24.8505 10.6452Z" fill="#E31863"/>
        <path d="M64.514 26.4292C59.4252 29.8301 52.3996 26.8084 47.7319 30.6951C47.6875 30.7376 47.6596 30.794 47.6531 30.8546C47.6466 30.9151 47.6617 30.9761 47.6961 31.0267C47.7304 31.0774 47.7816 31.1147 47.8409 31.132C47.9002 31.1494 47.9638 31.1457 48.0206 31.1217C53.5665 28.7518 60.0508 30.624 65.1155 27.1283C65.6328 26.7965 65.0072 26.1092 64.514 26.4292Z" fill="#E31863"/>
        <path d="M48.3456 31.0506C53.4584 29.7115 57.5968 32.1526 62.3968 33.302C66.2343 34.0881 70.1357 34.5323 74.0541 34.6292C74.1181 34.631 74.1807 34.6108 74.2313 34.5721C74.2819 34.5335 74.3174 34.4787 74.3315 34.4172C74.3457 34.3557 74.3378 34.2912 74.3092 34.2348C74.2805 34.1784 74.2329 34.1335 74.1744 34.1078C69.9999 31.9985 64.0449 32.6858 59.4735 31.3468C55.5998 30.1618 52.099 28.9768 48.1771 30.4818C47.8403 30.6003 48.0087 31.1454 48.3456 31.0506Z" fill="#E31863"/>
        <path d="M73.0074 33.9301C70.6014 35.1151 68.0028 35.3284 65.5006 36.1697C65.4495 36.1964 65.4082 36.2383 65.3825 36.2893C65.3568 36.3403 65.3479 36.398 65.3572 36.4542C65.3665 36.5105 65.3935 36.5624 65.4343 36.6028C65.4751 36.6431 65.5277 36.6699 65.5848 36.6793C68.2936 36.5858 70.941 35.8587 73.3082 34.5581C73.6931 34.3448 73.4164 33.7405 73.0074 33.9301Z" fill="#E31863"/>
        <path d="M65.4043 37.2007C67.3281 36.9738 69.2688 36.9183 71.2028 37.0348C73.0141 37.2858 74.8095 37.6379 76.5803 38.0894C76.857 38.0894 77.0254 37.6865 76.7728 37.5562C73.1871 35.9 69.1125 35.5841 65.308 36.6675C65.2429 36.6865 65.1871 36.7286 65.1514 36.7856C65.1157 36.8425 65.1026 36.9105 65.1145 36.9764C65.1264 37.0423 65.1625 37.1016 65.2159 37.143C65.2694 37.1844 65.3364 37.2049 65.4043 37.2007Z" fill="#E31863"/>
        <path d="M96.8031 8.14483C87.6481 6.8295 78.9383 -0.327802 69.4705 1.60372C69.3979 1.62327 69.3353 1.66899 69.2952 1.73182C69.2551 1.79465 69.2404 1.87002 69.254 1.94302C69.2676 2.01601 69.3085 2.08132 69.3687 2.12601C69.4288 2.1707 69.5038 2.19153 69.5788 2.18436C79.4917 1.22453 87.1188 8.68992 96.755 8.72547C96.8249 8.71844 96.8901 8.68753 96.9392 8.6381C96.9884 8.58867 97.0185 8.52384 97.0242 8.45486C97.0299 8.38589 97.0109 8.31712 96.9705 8.26049C96.9301 8.20386 96.8709 8.16294 96.8031 8.14483Z" fill="#E31863"/>
        <path d="M69.4344 2.137C70.7457 2.72949 73.9458 5.46681 74.6435 5.18241C74.2706 5.33646 72.851 4.51882 72.4781 4.37662C68.6702 2.98655 64.7021 2.06709 60.6644 1.63931C52.6523 0.525424 43.4733 0.15808 35.8702 3.27459C35.5574 3.40493 35.7138 3.93818 36.0507 3.84338C43.1718 1.83771 50.6273 1.24957 57.9817 2.1133C61.8412 2.47643 65.6612 3.1703 69.3984 4.18702C70.7457 4.55437 73.5367 4.85061 74.5713 5.8697C74.7638 6.04745 75.1849 5.8697 75.1006 5.62085C74.5593 3.61823 71.3593 2.33845 69.6149 1.65116C69.3021 1.52081 69.1337 1.9948 69.4344 2.137Z" fill="#E31863"/>
        <path d="M35.8823 3.85528C38.2883 4.49517 40.8146 5.6209 43.3169 6.03565C43.5936 6.03565 43.762 5.65645 43.5094 5.5261C41.1607 4.35907 38.6324 3.58232 36.0266 3.22723C35.6657 3.15614 35.5575 3.76048 35.8823 3.85528Z" fill="#E31863"/>
        <path d="M44.6162 6.41474C29.8913 2.18435 15.3227 9.96969 0.694013 11.1547C0.429349 11.1547 0.441379 11.6287 0.694013 11.6287C15.7558 11.3798 29.1935 3.71298 44.4839 6.88873C44.7726 6.92428 44.8809 6.48584 44.6162 6.41474Z" fill="#E31863"/>
        <path d="M1.23569 11.9487C9.03125 11.7591 15.3712 15.9065 22.7457 17.5773C29.5547 19.106 37.1819 18.6794 42.7398 23.4311C43.0646 23.7037 43.5579 23.1823 43.2451 22.8742C38.7217 18.4305 31.8044 18.2765 25.8735 17.2574C17.4524 15.8117 9.98163 10.9177 1.21163 11.3325C0.874781 11.368 0.898847 11.9605 1.23569 11.9487Z" fill="#E31863"/>
        <path d="M42.4628 22.4832C39.1711 22.4802 35.9188 23.1881 32.9349 24.5569C32.8825 24.591 32.8429 24.6411 32.822 24.6994C32.8011 24.7578 32.8001 24.8213 32.8191 24.8802C32.8382 24.9392 32.8762 24.9904 32.9275 25.0262C32.9788 25.0619 33.0405 25.0802 33.1033 25.0783C34.7154 24.8413 36.2672 24.2488 37.9154 23.8933C39.4457 23.5885 40.9919 23.367 42.547 23.2297C42.932 23.206 42.9079 22.4595 42.4628 22.4832Z" fill="#E31863"/>
        <path d="M32.8866 24.7583C38.6371 25.1731 41.4641 30.4936 46.1198 33.0532C50.583 35.4232 55.9966 35.9327 60.9651 36.1934C61.3019 36.1934 61.4583 35.6839 61.1094 35.6009C54.4568 34.0605 48.2732 34.2145 42.7754 29.4864C39.7679 26.8913 37.1453 24.1421 32.9227 24.1421C32.5257 24.0947 32.4415 24.7228 32.8866 24.7583Z" fill="#E31863"/>
        <path d="M59.1126 35.7313C57.7411 36.7148 56.2013 37.4732 54.8418 38.4804C54.7767 38.5299 54.7307 38.5998 54.7115 38.6786C54.6922 38.7574 54.7008 38.8403 54.7359 38.9135C54.771 38.9868 54.8304 39.0461 54.9044 39.0815C54.9784 39.117 55.0624 39.1265 55.1426 39.1085C65.9698 37.2006 76.2556 42.3435 86.9624 42.6634C87.2752 42.6634 87.4316 42.1894 87.0948 42.0946C80.0367 40.3485 72.8662 39.0776 65.6329 38.2908C65.6329 38.2908 56.4058 37.6983 56.4539 38.1842C56.4539 38.0894 59.2329 36.6555 59.6058 36.1815C59.9787 35.7076 59.4133 35.518 59.1126 35.7313Z" fill="#E31863"/>
        <path d="M95.9729 9.03354C96.7669 9.15204 97.5248 9.36534 98.379 9.43644C98.7158 9.43644 98.7399 8.99799 98.379 8.92689C97.585 8.761 96.8391 8.48845 95.9729 8.36995C95.6 8.3107 95.5519 8.96244 95.9729 9.03354Z" fill="#E31863"/>
        <path d="M100.34 7.83675C104.815 8.86136 109.037 10.7592 112.755 13.418C112.807 13.459 112.873 13.4791 112.939 13.4744C113.006 13.4697 113.068 13.4405 113.113 13.3927C113.159 13.3448 113.184 13.2818 113.185 13.2163C113.185 13.1507 113.161 13.0873 113.116 13.0388C109.952 9.61422 104.899 8.42924 100.508 7.3746C100.196 7.29166 100.015 7.7538 100.34 7.83675Z" fill="#E31863"/>
        <path d="M115.486 16.416C116.014 16.6827 116.475 17.0635 116.833 17.5299C117.194 17.9802 117.411 18.5016 117.736 18.9756C117.904 19.2363 118.277 18.9756 118.241 18.7386C118.103 18.0955 117.813 17.4934 117.395 16.9808C116.977 16.4683 116.443 16.0597 115.835 15.788C115.462 15.6102 115.125 16.2264 115.486 16.416Z" fill="#E31863"/>
        <path d="M121.273 30.0315C120.953 32.9421 120.332 35.8128 119.42 38.5989C118.048 42.2724 115.63 44.0854 112.202 45.8747C111.925 46.0169 112.045 46.5146 112.382 46.4317C119.997 44.5238 121.068 36.5607 121.742 30.0789C121.728 30.0303 121.7 29.9869 121.661 29.9543C121.623 29.9218 121.575 29.9016 121.524 29.8964C121.473 29.8913 121.422 29.9015 121.377 29.9256C121.332 29.9497 121.296 29.9866 121.273 30.0315Z" fill="#E31863"/>
        <path d="M76.6526 43.2203C86.0121 44.7727 95.6362 49.7377 105.284 48.4817C105.645 48.4817 105.633 47.8299 105.284 47.8299C95.2873 48.0432 86.5534 42.9952 76.7488 42.6397C76.6753 42.6365 76.6031 42.6601 76.546 42.7058C76.4889 42.7516 76.4509 42.8164 76.439 42.8879C76.4271 42.9595 76.4423 43.0328 76.4817 43.0941C76.521 43.1553 76.5818 43.2003 76.6526 43.2203Z" fill="#E31863"/>
        <path d="M105.224 51.0769C106.662 51.0097 108.088 50.7993 109.483 50.4488C109.559 50.4488 109.633 50.4189 109.687 50.3655C109.741 50.3122 109.772 50.2399 109.772 50.1644C109.772 50.089 109.741 50.0167 109.687 49.9633C109.633 49.91 109.559 49.88 109.483 49.88C108.087 49.7023 106.62 50.3066 105.224 50.4133C104.827 50.4488 104.851 51.0769 105.224 51.0769Z" fill="#E31863"/>
        <path d="M80.3098 0.63205C85.8677 1.58004 90.5354 5.37198 96.1896 5.86968C96.4784 5.86968 96.6107 5.43123 96.3099 5.36013C90.8121 4.0685 85.976 0.845346 80.37 0.0040076C79.9609 -0.0552416 79.9128 0.560951 80.3098 0.63205Z" fill="#E31863"/>
        <path d="M121.73 21.7485C122.788 26.5951 125.074 30.5529 122.788 35.4706C122.656 35.755 123.089 35.9327 123.269 35.7195C126.554 31.7853 123.703 25.7063 122.343 21.4997C122.235 21.2153 121.657 21.393 121.73 21.7485Z" fill="#E31863"/>
        <path d="M126.277 28.3607C126.531 29.0741 126.673 29.8212 126.699 30.5766C126.699 31.2402 126.542 31.8682 126.518 32.52C126.502 32.5875 126.514 32.6586 126.551 32.7175C126.588 32.7764 126.648 32.8183 126.717 32.834C126.785 32.8497 126.857 32.8379 126.917 32.8013C126.977 32.7646 127.02 32.706 127.036 32.6385C127.546 31.1806 127.486 29.5877 126.867 28.1711C126.759 27.8156 126.181 28.0289 126.277 28.3607Z" fill="#E31863"/>
        <path d="M124.232 37.1177C123.021 41.0297 120.387 44.3635 116.833 46.479C116.782 46.5149 116.746 46.5673 116.731 46.627C116.715 46.6867 116.722 46.7498 116.749 46.8052C116.777 46.8606 116.823 46.9046 116.88 46.9295C116.938 46.9544 117.002 46.9585 117.062 46.9412C121.212 45.4125 123.558 41.2769 124.773 37.3073C124.79 37.2388 124.781 37.1665 124.747 37.1044C124.713 37.0423 124.657 36.9949 124.59 36.9713C124.522 36.9476 124.448 36.9495 124.382 36.9766C124.316 37.0036 124.262 37.0539 124.232 37.1177Z" fill="#E31863"/>
        <path d="M21.4821 20.919C24.6157 21.0762 27.7222 21.5729 30.7454 22.4002C30.8116 22.4123 30.8799 22.4011 30.9387 22.3688C30.9974 22.3364 31.0428 22.2849 31.0671 22.223C31.0913 22.1612 31.0929 22.093 31.0715 22.0301C31.0501 21.9673 31.0071 21.9138 30.9499 21.8788C27.9975 20.5445 24.74 20.0009 21.5062 20.3028C21.4232 20.2997 21.3424 20.3291 21.2815 20.3847C21.2206 20.4402 21.1846 20.5173 21.1814 20.599C21.1782 20.6808 21.2081 20.7604 21.2645 20.8204C21.3209 20.8804 21.3992 20.9159 21.4821 20.919Z" fill="#E31863"/>
        </svg>
        
        
    )
}