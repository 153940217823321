

export const ThirdDashboardSatellite = () => {

    return (


        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.13291 28.6423C7.79414 28.6412 6.49719 28.1758 5.46303 27.3256C4.42887 26.4755 3.72147 25.293 3.46136 23.9798C3.20125 22.6665 3.40452 21.3037 4.03654 20.1235C4.66855 18.9433 5.69022 18.0187 6.92747 17.5073C8.16472 16.9959 9.541 16.9293 10.8218 17.3189C12.1027 17.7084 13.2088 18.53 13.9519 19.6437C14.6949 20.7573 15.0288 22.0942 14.8967 23.4264C14.7646 24.7586 14.1747 26.0039 13.2274 26.9499C12.6905 27.4884 12.0522 27.9151 11.3494 28.2056C10.6467 28.4961 9.89334 28.6445 9.13291 28.6423ZM9.13291 17.9829C8.005 17.9993 6.9177 18.4064 6.0565 19.135C5.19531 19.8636 4.61359 20.8684 4.41057 21.978C4.20755 23.0876 4.39582 24.2332 4.94326 25.2195C5.4907 26.2058 6.36338 26.9716 7.41243 27.3863C8.46149 27.8009 9.62191 27.8387 10.6957 27.4932C11.7696 27.1478 12.6903 26.4404 13.3007 25.4919C13.9112 24.5433 14.1737 23.4123 14.0433 22.2918C13.913 21.1714 13.3979 20.1308 12.586 19.3478C12.1284 18.9044 11.5879 18.5556 10.9954 18.3215C10.4028 18.0873 9.76995 17.9722 9.13291 17.9829Z" fill="url(#paint0_linear)"/>
<path d="M9.13273 25.7489C8.36882 25.7418 7.63843 25.4342 7.09951 24.8927C6.5606 24.3513 6.25647 23.6194 6.25292 22.8555C6.24136 22.7888 6.24451 22.7204 6.26216 22.6551C6.2798 22.5898 6.31152 22.5292 6.35508 22.4774C6.39864 22.4256 6.45299 22.384 6.51433 22.3555C6.57566 22.3269 6.64249 22.3121 6.71014 22.3121C6.77779 22.3121 6.84462 22.3269 6.90596 22.3555C6.96729 22.384 7.02164 22.4256 7.0652 22.4774C7.10876 22.5292 7.14047 22.5898 7.15811 22.6551C7.17576 22.7204 7.17892 22.7888 7.16736 22.8555C7.17094 23.3756 7.37915 23.8734 7.74695 24.2412C8.11476 24.6091 8.61259 24.8173 9.13273 24.8208C9.19367 24.8208 9.25402 24.8328 9.31032 24.8562C9.36662 24.8795 9.41778 24.9137 9.46087 24.9568C9.50396 24.9998 9.53813 25.051 9.56145 25.1073C9.58478 25.1636 9.59678 25.2239 9.59678 25.2849C9.59678 25.3458 9.58478 25.4062 9.56145 25.4625C9.53813 25.5188 9.50396 25.5699 9.46087 25.613C9.41778 25.6561 9.36662 25.6903 9.31032 25.7136C9.25402 25.7369 9.19367 25.7489 9.13273 25.7489Z" fill="url(#paint1_linear)"/>
<path d="M20.1333 23.265H19.9559C19.9003 23.2399 19.8502 23.2041 19.8085 23.1597C19.7667 23.1153 19.7342 23.063 19.7126 23.006C19.6911 22.9489 19.681 22.8882 19.6829 22.8273C19.6848 22.7663 19.6988 22.7064 19.7239 22.6508L27.4762 4.56668L9.41935 12.2507C9.33089 12.3002 9.229 12.3202 9.12841 12.308C9.02782 12.2957 8.93373 12.2517 8.85976 12.1825C8.80476 12.1284 8.76459 12.0611 8.74308 11.987C8.72157 11.9129 8.71945 11.8345 8.73693 11.7594C8.74811 11.687 8.77788 11.6188 8.82333 11.5614C8.86879 11.504 8.92837 11.4593 8.99625 11.4318L28.104 3.24278C28.1888 3.21054 28.2809 3.20239 28.37 3.21926C28.4591 3.23612 28.5418 3.27733 28.609 3.33832C28.6729 3.40372 28.7161 3.48654 28.7331 3.57638C28.7501 3.66622 28.7401 3.75909 28.7045 3.84331L20.5155 23.0193C20.4805 23.0914 20.4263 23.1524 20.3589 23.1958C20.2915 23.2391 20.2134 23.263 20.1333 23.265Z" fill="url(#paint2_linear)"/>
<path d="M16.7077 19.8392C16.6083 19.8404 16.5117 19.8066 16.4348 19.7437C16.3382 19.6704 16.2742 19.5623 16.2563 19.4424C16.2385 19.3225 16.2682 19.2004 16.3392 19.1022L26.1524 5.83594L12.8725 15.6218C12.8032 15.681 12.718 15.7184 12.6275 15.7294C12.537 15.7405 12.4453 15.7246 12.3638 15.6839C12.2823 15.6431 12.2146 15.5793 12.1691 15.5003C12.1237 15.4213 12.1025 15.3306 12.1082 15.2397C12.116 15.1749 12.137 15.1125 12.1698 15.0561C12.2027 14.9998 12.2468 14.9508 12.2993 14.9121L28.0768 3.27004C28.166 3.21256 28.2721 3.18735 28.3776 3.19861C28.483 3.20986 28.5815 3.25691 28.6565 3.3319C28.7315 3.4069 28.7785 3.50532 28.7898 3.61078C28.801 3.71624 28.7758 3.82238 28.7183 3.91151L17.0762 19.6481C17.0355 19.708 16.9805 19.7569 16.9161 19.7902C16.8518 19.8236 16.7802 19.8404 16.7077 19.8392Z" fill="url(#paint3_linear)"/>
<path d="M9.13297 32C7.7674 31.998 6.41962 31.6902 5.18858 31.0992C5.12659 31.0781 5.06976 31.0442 5.02183 30.9996C4.97389 30.955 4.93595 30.9007 4.91047 30.8404C4.88499 30.7801 4.87257 30.7151 4.87401 30.6496C4.87546 30.5842 4.89074 30.5198 4.91885 30.4606C4.94696 30.4015 4.98727 30.349 5.03712 30.3065C5.08698 30.2641 5.14524 30.2327 5.2081 30.2144C5.27096 30.196 5.33699 30.1912 5.40184 30.2003C5.4667 30.2093 5.5289 30.2319 5.58438 30.2667C7.12164 30.9977 8.84703 31.2367 10.5251 30.9509C12.2032 30.6651 13.7522 29.8685 14.9608 28.6698C15.883 27.7603 16.5714 26.6411 16.9671 25.4078C16.9946 25.3334 17.0402 25.2669 17.0998 25.2145C17.1593 25.1621 17.231 25.1253 17.3084 25.1076C17.3918 25.0903 17.4784 25.0964 17.5586 25.1251C17.6388 25.1538 17.7096 25.204 17.7632 25.2703C17.8167 25.3365 17.851 25.4162 17.8623 25.5007C17.8736 25.5851 17.8613 25.6711 17.827 25.749C17.3822 27.0979 16.6191 28.3197 15.6023 29.3113C14.7545 30.1639 13.7465 30.8404 12.6362 31.3019C11.5259 31.7633 10.3353 32.0006 9.13297 32Z" fill="url(#paint4_linear)"/>
<path d="M1.51688 27.4823C1.43478 27.4814 1.3545 27.458 1.28474 27.4147C1.21498 27.3714 1.15839 27.3098 1.12107 27.2367C1.01189 27.0592 0.916345 26.8545 0.820806 26.6634C0.770129 26.5512 0.76611 26.4235 0.809622 26.3083C0.853135 26.1931 0.940613 26.0999 1.05283 26.0493C1.16504 25.9986 1.29279 25.9946 1.40797 26.0381C1.52315 26.0816 1.61633 26.1691 1.66701 26.2813C1.73964 26.4607 1.82636 26.6342 1.92633 26.7999C1.95501 26.8537 1.97281 26.9126 1.97873 26.9732C1.98465 27.0339 1.97856 27.0951 1.96082 27.1534C1.94308 27.2117 1.91402 27.2659 1.87532 27.313C1.83662 27.3601 1.78902 27.3991 1.73525 27.4278C1.66678 27.4602 1.59256 27.4788 1.51688 27.4823Z" fill="url(#paint5_linear)"/>
<path d="M3.31864 29.7343C3.20255 29.7329 3.09096 29.6892 3.00472 29.6115C2.90919 29.5159 2.80001 29.4204 2.70447 29.3112L2.29501 28.8881C2.23415 28.794 2.20938 28.6811 2.22528 28.5702C2.24117 28.4593 2.29665 28.3579 2.38149 28.2847C2.46633 28.2115 2.57478 28.1715 2.68683 28.172C2.79887 28.1725 2.90695 28.2136 2.99108 28.2876L3.34593 28.6697L3.63256 28.9291C3.71606 29.0177 3.76255 29.1349 3.76255 29.2566C3.76255 29.3784 3.71606 29.4956 3.63256 29.5842C3.54974 29.6708 3.43809 29.7242 3.31864 29.7343Z" fill="url(#paint6_linear)"/>
<path d="M5.37956 31.1401C5.31665 31.1542 5.25139 31.1542 5.18848 31.1401L4.68349 30.7989C4.59027 30.7326 4.52483 30.6342 4.49972 30.5226C4.47461 30.411 4.49161 30.294 4.54745 30.1942C4.60329 30.0944 4.69404 30.0187 4.80228 29.9816C4.91051 29.9446 5.0286 29.9489 5.13389 29.9936L5.65252 30.2666C5.76235 30.3197 5.84684 30.4139 5.88771 30.5289C5.92858 30.6438 5.92254 30.7702 5.8709 30.8808C5.82794 30.9712 5.75687 31.0453 5.66832 31.0921C5.57976 31.1388 5.47847 31.1556 5.37956 31.1401Z" fill="url(#paint7_linear)"/>
<path d="M1.24397 26.9363C1.15503 26.9347 1.06828 26.9083 0.993531 26.8601C0.918778 26.8119 0.858976 26.7437 0.820864 26.6634C0.0499759 24.9645 -0.185328 23.0712 0.146254 21.2353C0.477835 19.3994 1.36058 17.708 2.67705 16.3861C3.68376 15.3521 4.92419 14.5751 6.29388 14.1205C6.41152 14.0825 6.53945 14.0928 6.64951 14.1491C6.75957 14.2054 6.84275 14.3031 6.88076 14.4207C6.91877 14.5384 6.90849 14.6663 6.85218 14.7764C6.79587 14.8864 6.69814 14.9696 6.58049 15.0076C5.34584 15.4073 4.22653 16.1004 3.31852 17.0276C2.13653 18.2201 1.34546 19.7441 1.05047 21.397C0.755479 23.0499 0.970542 24.7535 1.66707 26.2812C1.71422 26.3938 1.71644 26.5202 1.67329 26.6345C1.63013 26.7487 1.54489 26.8421 1.43505 26.8954C1.37486 26.9221 1.30981 26.936 1.24397 26.9363Z" fill="url(#paint8_linear)"/>
<path d="M30.1788 2.25998C30.1198 2.26123 30.0611 2.24968 30.0069 2.22612C29.9527 2.20256 29.9043 2.16755 29.8649 2.1235C29.8207 2.08261 29.7854 2.033 29.7612 1.9778C29.7371 1.9226 29.7246 1.86301 29.7246 1.80276C29.7246 1.74251 29.7371 1.68292 29.7612 1.62772C29.7854 1.57252 29.8207 1.52292 29.8649 1.48202L31.2298 0.117182C31.3182 0.0385657 31.4334 -0.00328118 31.5517 0.000201128C31.67 0.00368344 31.7825 0.0522323 31.8661 0.135916C31.9498 0.219601 31.9984 0.332096 32.0019 0.450392C32.0053 0.568688 31.9635 0.683844 31.8849 0.772306L30.52 2.13715C30.4758 2.18163 30.4223 2.21573 30.3633 2.23697C30.3043 2.25822 30.2413 2.26608 30.1788 2.25998Z" fill="url(#paint9_linear)"/>
<defs>
<linearGradient id="paint0_linear" x1="24.0683" y1="26.3854" x2="-11.2899" y2="16.2583" gradientUnits="userSpaceOnUse">
<stop stop-color="#25D8FF"/>
<stop offset="1" stop-color="#545DC4"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="12.2438" y1="25.0788" x2="1.96893" y2="22.2096" gradientUnits="userSpaceOnUse">
<stop stop-color="#25D8FF"/>
<stop offset="1" stop-color="#545DC4"/>
</linearGradient>
<linearGradient id="paint2_linear" x1="44.5537" y1="19.3544" x2="-16.6127" y2="1.8689" gradientUnits="userSpaceOnUse">
<stop stop-color="#25D8FF"/>
<stop offset="1" stop-color="#545DC4"/>
</linearGradient>
<linearGradient id="paint3_linear" x1="41.9736" y1="16.5938" x2="-8.97989" y2="1.9633" gradientUnits="userSpaceOnUse">
<stop stop-color="#25D8FF"/>
<stop offset="1" stop-color="#545DC4"/>
</linearGradient>
<linearGradient id="paint4_linear" x1="28.1304" y1="30.6541" x2="-5.14505" y2="12.7137" gradientUnits="userSpaceOnUse">
<stop stop-color="#25D8FF"/>
<stop offset="1" stop-color="#545DC4"/>
</linearGradient>
<linearGradient id="paint5_linear" x1="2.92992" y1="27.1949" x2="-0.835872" y2="26.316" gradientUnits="userSpaceOnUse">
<stop stop-color="#25D8FF"/>
<stop offset="1" stop-color="#545DC4"/>
</linearGradient>
<linearGradient id="paint6_linear" x1="4.98071" y1="29.4297" x2="0.260725" y2="28.0954" gradientUnits="userSpaceOnUse">
<stop stop-color="#25D8FF"/>
<stop offset="1" stop-color="#545DC4"/>
</linearGradient>
<linearGradient id="paint7_linear" x1="7.04116" y1="30.9178" x2="2.82087" y2="29.4741" gradientUnits="userSpaceOnUse">
<stop stop-color="#25D8FF"/>
<stop offset="1" stop-color="#545DC4"/>
</linearGradient>
<linearGradient id="paint8_linear" x1="12.357" y1="24.4328" x2="-9.93408" y2="20.9998" gradientUnits="userSpaceOnUse">
<stop stop-color="#25D8FF"/>
<stop offset="1" stop-color="#545DC4"/>
</linearGradient>
<linearGradient id="paint9_linear" x1="33.8012" y1="1.8209" x2="26.8509" y2="-0.183413" gradientUnits="userSpaceOnUse">
<stop stop-color="#25D8FF"/>
<stop offset="1" stop-color="#545DC4"/>
</linearGradient>
</defs>
</svg>


    )}