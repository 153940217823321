
import styled from 'styled-components/macro';

// import StyledTableRow from '../TableRow/StyledTableRow';

// props {checkBox, columns}
export const StyledStepper = styled.div`
   
        svg{
            color: ${ props => props.firstTexFieldError ? 'rgba(227, 24, 99, 1)!important' : null }
            
            

    }
`
