
export const SecondDashboardSatellite = () => {

    return (

                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9274 23.4532C12.6828 23.4557 12.4402 23.4092 12.2138 23.3165C11.9875 23.2238 11.782 23.0867 11.6096 22.9133L9.09253 20.398C8.91843 20.2261 8.78084 20.0209 8.68799 19.7946C8.59515 19.5683 8.54897 19.3256 8.55222 19.0811C8.54665 18.8362 8.59177 18.5928 8.68474 18.3662C8.77772 18.1396 8.91655 17.9346 9.09253 17.7641L16.1824 10.666C16.3549 10.4926 16.5603 10.3555 16.7867 10.2627C17.013 10.17 17.2557 10.1235 17.5003 10.126C17.9933 10.1268 18.4664 10.3206 18.8181 10.666L21.3351 13.1944C21.5121 13.3652 21.6528 13.5697 21.749 13.796C21.8451 14.0223 21.8946 14.2655 21.8946 14.5114C21.8946 14.7572 21.8451 15.0004 21.749 15.2267C21.6528 15.4529 21.5121 15.6575 21.3351 15.8283L14.2189 22.966C13.8684 23.2905 13.405 23.4653 12.9274 23.4532ZM17.5003 11.1137C17.3751 11.1103 17.2507 11.1332 17.1349 11.1808C17.0191 11.2285 16.9147 11.2998 16.8282 11.3903L9.72508 18.4753C9.56024 18.655 9.46881 18.8899 9.46881 19.1337C9.46881 19.3775 9.56024 19.6125 9.72508 19.7922L12.2421 22.3075C12.4246 22.4668 12.6587 22.5546 12.901 22.5546C13.1434 22.5546 13.3775 22.4668 13.56 22.3075L20.6499 15.2225C20.8237 15.0474 20.9212 14.8107 20.9212 14.564C20.9212 14.3174 20.8237 14.0807 20.6499 13.9056L18.1328 11.3903C17.965 11.2209 17.7387 11.1219 17.5003 11.1137Z" fill="url(#paint0_linear)"/>
        <path d="M26.8567 12.2989C25.4455 12.3002 24.0655 11.8837 22.8909 11.102C21.7163 10.3202 20.7997 9.2083 20.257 7.90651C19.7142 6.60471 19.5694 5.17139 19.8411 3.78748C20.1127 2.40356 20.7885 1.13107 21.7831 0.130605C21.8671 0.0469698 21.9808 0 22.0994 0C22.218 0 22.3317 0.0469698 22.4157 0.130605L31.8645 9.58607C31.9072 9.62553 31.9413 9.67339 31.9646 9.72665C31.9879 9.77991 32 9.83741 32 9.89555C32 9.95368 31.9879 10.0112 31.9646 10.0645C31.9413 10.1177 31.9072 10.1656 31.8645 10.205C31.2088 10.8662 30.4289 11.3916 29.5695 11.7509C28.7102 12.1102 27.7883 12.2965 26.8567 12.2989ZM22.1389 1.07878C20.9698 2.25407 20.3158 3.84533 20.3207 5.5025C20.3257 7.15967 20.9892 8.747 22.1653 9.9153C23.3414 11.0836 24.9337 11.7372 26.592 11.7322C28.2504 11.7273 29.8388 11.0643 31.0079 9.88897L22.1389 1.07878Z" fill="url(#paint1_linear)"/>
        <path d="M21.0058 13.9713C20.9469 13.9719 20.8885 13.9606 20.8341 13.9379C20.7798 13.9153 20.7306 13.8819 20.6895 13.8397L18.1725 11.3243C18.1255 11.2758 18.0894 11.2178 18.0667 11.1542C18.044 11.0906 18.0351 11.0229 18.0407 10.9556C18.0527 10.8881 18.0789 10.8238 18.1175 10.7671C18.1561 10.7103 18.2063 10.6624 18.2647 10.6264L21.3089 8.83536C21.4027 8.77834 21.5146 8.75887 21.6221 8.78085C21.7296 8.80283 21.8249 8.86465 21.8887 8.95389C22.0604 9.17545 22.2453 9.38652 22.4422 9.586C22.6398 9.78098 22.8512 9.96144 23.0748 10.1259C23.1595 10.1935 23.2169 10.2894 23.2362 10.3959C23.2556 10.5024 23.2357 10.6124 23.1802 10.7054L21.388 13.7475C21.3551 13.8073 21.3077 13.858 21.2501 13.8948C21.1926 13.9316 21.1267 13.9534 21.0585 13.9582L21.0058 13.9713ZM19.1477 11.1136L20.8608 12.8256L22.1787 10.6C22.0337 10.4683 21.8887 10.3498 21.7701 10.2181C21.633 10.0915 21.5053 9.95502 21.388 9.80989L19.1477 11.1136Z" fill="url(#paint2_linear)"/>
        <path d="M27.0544 32C26.8414 32.0008 26.6304 31.9593 26.4336 31.878C26.2368 31.7966 26.0581 31.6769 25.9079 31.5259L17.8297 23.4664C17.746 23.3825 17.699 23.2688 17.699 23.1503C17.699 23.0318 17.746 22.9182 17.8297 22.8343L22.2971 18.3699C22.3811 18.2863 22.4948 18.2393 22.6134 18.2393C22.7319 18.2393 22.8457 18.2863 22.9296 18.3699L31.0079 26.4294C31.1581 26.5788 31.2773 26.7563 31.3587 26.9517C31.44 27.1472 31.4819 27.3569 31.4819 27.5686C31.4819 27.7803 31.44 27.9899 31.3587 28.1854C31.2773 28.3809 31.1581 28.5584 31.0079 28.7077L28.1746 31.5259C27.8786 31.8257 27.4759 31.9961 27.0544 32ZM18.7785 23.1503L26.5273 30.8675C26.665 31.001 26.8494 31.0757 27.0413 31.0757C27.2332 31.0757 27.4175 31.001 27.5552 30.8675L30.3622 28.0493C30.432 27.9858 30.488 27.9085 30.5265 27.8224C30.5651 27.7363 30.5854 27.6432 30.5862 27.5488C30.5872 27.4523 30.5678 27.3566 30.5292 27.2681C30.4906 27.1796 30.4337 27.1002 30.3622 27.0352L22.6134 19.2786L18.7785 23.1503Z" fill="url(#paint3_linear)"/>
        <path d="M24.2474 29.6954C24.1295 29.6911 24.0171 29.6444 23.9311 29.5637L20.6234 26.2582C20.5426 26.1722 20.4958 26.06 20.4916 25.9421C20.4933 25.8238 20.5404 25.7106 20.6234 25.6261L25.0908 21.1617C25.1748 21.0781 25.2885 21.0311 25.4071 21.0311C25.5256 21.0311 25.6394 21.0781 25.7234 21.1617L29.0311 24.4672C29.114 24.5517 29.1612 24.6649 29.1629 24.7832C29.1605 24.8976 29.1132 25.0064 29.0311 25.0861L24.5637 29.5505C24.4783 29.6341 24.3665 29.6853 24.2474 29.6954ZM21.6117 25.9421L24.2474 28.576L28.0823 24.7437L25.4466 22.1099L21.6117 25.9421Z" fill="url(#paint4_linear)"/>
        <path d="M27.6476 28.6156C27.5885 28.6178 27.5296 28.6072 27.475 28.5845C27.4204 28.5617 27.3714 28.5274 27.3314 28.4839L20.0701 21.2277C20.0291 21.1872 19.9965 21.139 19.9743 21.0859C19.952 21.0328 19.9406 20.9758 19.9406 20.9182C19.9406 20.8606 19.952 20.8036 19.9743 20.7504C19.9965 20.6973 20.0291 20.6491 20.0701 20.6087C20.1096 20.566 20.1575 20.532 20.2108 20.5087C20.2641 20.4854 20.3216 20.4733 20.3798 20.4733C20.438 20.4733 20.4955 20.4854 20.5488 20.5087C20.6021 20.532 20.65 20.566 20.6895 20.6087L27.9639 27.8649C28.0049 27.9054 28.0375 27.9535 28.0598 28.0067C28.082 28.0598 28.0935 28.1168 28.0935 28.1744C28.0935 28.232 28.082 28.289 28.0598 28.3421C28.0375 28.3953 28.0049 28.4434 27.9639 28.4839C27.8801 28.5679 27.7664 28.6153 27.6476 28.6156Z" fill="url(#paint5_linear)"/>
        <path d="M19.3848 22.3602C19.2669 22.356 19.1546 22.3092 19.0685 22.2285L16.6833 19.8317C16.6402 19.7911 16.6059 19.7421 16.5824 19.6877C16.559 19.6334 16.5469 19.5748 16.5469 19.5156C16.5469 19.4564 16.559 19.3979 16.5824 19.3435C16.6059 19.2892 16.6402 19.2402 16.6833 19.1996L18.6732 17.211C18.7137 17.17 18.7619 17.1374 18.815 17.1152C18.8682 17.093 18.9253 17.0815 18.9829 17.0815C19.0405 17.0815 19.0976 17.093 19.1507 17.1152C19.2039 17.1374 19.2521 17.17 19.2926 17.211L21.691 19.6078C21.7739 19.6923 21.8211 19.8055 21.8228 19.9239C21.8186 20.0417 21.7718 20.154 21.691 20.2399L19.7011 22.2285C19.6151 22.3092 19.5027 22.356 19.3848 22.3602ZM17.6189 19.5156L19.3848 21.2803L20.7026 19.9634L18.9368 18.1987L17.6189 19.5156Z" fill="url(#paint6_linear)"/>
        <path d="M8.85536 14.3139C8.79646 14.3145 8.73806 14.3031 8.6837 14.2805C8.62933 14.2579 8.58013 14.2244 8.53908 14.1822L0.473981 6.12268C0.323766 5.97338 0.204561 5.79588 0.12322 5.60039C0.041878 5.4049 0 5.19527 0 4.98355C0 4.77183 0.041878 4.5622 0.12322 4.3667C0.204561 4.17121 0.323766 3.99372 0.473981 3.84442L3.33366 1.0262C3.48307 0.876089 3.66069 0.756976 3.85632 0.675691C4.05194 0.594405 4.26172 0.552551 4.47359 0.552551C4.68545 0.552551 4.89522 0.594405 5.09085 0.675691C5.28648 0.756976 5.4641 0.876089 5.6135 1.0262L13.6786 9.08575C13.7623 9.16967 13.8093 9.28332 13.8093 9.4018C13.8093 9.52029 13.7623 9.63395 13.6786 9.71787L9.21117 14.1822C9.16694 14.2313 9.11147 14.2689 9.04949 14.2919C8.98752 14.3148 8.9209 14.3224 8.85536 14.3139ZM4.44064 1.44762C4.34489 1.44411 4.2495 1.46109 4.16086 1.49743C4.07222 1.53377 3.99237 1.58863 3.92669 1.65833L1.10654 4.52921C1.03932 4.59676 0.986099 4.67689 0.949918 4.76502C0.913737 4.85315 0.895307 4.94755 0.895685 5.04281C0.900282 5.23411 0.975362 5.41699 1.10654 5.5564L8.85536 13.2999L12.7034 9.45448L4.95459 1.711C4.89211 1.63364 4.81408 1.57022 4.72555 1.52486C4.63703 1.47949 4.53997 1.45319 4.44064 1.44762Z" fill="url(#paint7_linear)"/>
        <path d="M6.06145 11.522C6.00442 11.5232 5.94779 11.5121 5.89547 11.4893C5.84315 11.4666 5.79638 11.4328 5.75835 11.3903L2.43743 8.08483C2.39639 8.0444 2.3638 7.99623 2.34156 7.9431C2.31931 7.88997 2.30786 7.83295 2.30786 7.77536C2.30786 7.71777 2.31931 7.66076 2.34156 7.60763C2.3638 7.5545 2.39639 7.50632 2.43743 7.46589L6.91804 2.98837C6.9585 2.94735 7.00671 2.91478 7.05988 2.89256C7.11304 2.87033 7.1701 2.85889 7.22773 2.85889C7.28536 2.85889 7.34242 2.87033 7.39558 2.89256C7.44875 2.91478 7.49696 2.94735 7.53742 2.98837L10.8452 6.29382C10.8874 6.33484 10.9209 6.38402 10.9435 6.43834C10.9662 6.49267 10.9775 6.55104 10.9769 6.60989C10.9792 6.66897 10.9686 6.72782 10.9458 6.78239C10.9231 6.83696 10.8887 6.88594 10.8452 6.92595L6.37773 11.3903C6.3377 11.4338 6.28868 11.4681 6.23407 11.4909C6.17947 11.5136 6.12057 11.5242 6.06145 11.522ZM3.4258 7.78195L6.06145 10.4158L9.92269 6.60989L7.28703 3.97606L3.4258 7.78195Z" fill="url(#paint8_linear)"/>
        <path d="M11.0956 12.0751C10.9791 12.0757 10.8666 12.0335 10.7793 11.9565L3.51805 4.68715C3.43591 4.60507 3.38977 4.49375 3.38977 4.37767C3.38977 4.2616 3.43591 4.15027 3.51805 4.0682C3.60018 3.98612 3.71158 3.94 3.82774 3.94C3.94389 3.94 4.05529 3.98612 4.13743 4.0682L11.4118 11.3244C11.4955 11.4083 11.5425 11.522 11.5425 11.6405C11.5425 11.759 11.4955 11.8726 11.4118 11.9565C11.3233 12.0315 11.2115 12.0734 11.0956 12.0751Z" fill="url(#paint9_linear)"/>
        <path d="M12.5585 15.4727C12.4401 15.471 12.3268 15.4238 12.2422 15.341L9.79107 12.9442C9.74799 12.9036 9.71368 12.8546 9.69022 12.8002C9.66676 12.7459 9.65466 12.6873 9.65466 12.6281C9.65466 12.569 9.66676 12.5104 9.69022 12.456C9.71368 12.4017 9.74799 12.3527 9.79107 12.3121L11.781 10.3235C11.8211 10.2847 11.8686 10.2542 11.9206 10.2339C11.9727 10.2135 12.0282 10.2037 12.0841 10.205C12.2009 10.2018 12.3144 10.2443 12.4004 10.3235L14.7988 12.7203C14.8825 12.8043 14.9295 12.9179 14.9295 13.0364C14.9295 13.1549 14.8825 13.2685 14.7988 13.3524L12.8089 15.341C12.7403 15.4087 12.6532 15.4545 12.5585 15.4727ZM10.7926 12.6281L12.5585 14.3928L13.8763 13.0759L12.1104 11.3112L10.7926 12.6281Z" fill="url(#paint10_linear)"/>
        <path d="M15.8266 16.6316C15.7677 16.6322 15.7093 16.6209 15.6549 16.5982C15.6006 16.5756 15.5514 16.5421 15.5103 16.4999C15.4266 16.416 15.3796 16.3024 15.3796 16.1839C15.3796 16.0654 15.4266 15.9517 15.5103 15.8678L17.3685 14.0241C17.4089 13.9831 17.4571 13.9506 17.5103 13.9283C17.5635 13.9061 17.6205 13.8947 17.6782 13.8947C17.7358 13.8947 17.7928 13.9061 17.846 13.9283C17.8992 13.9506 17.9474 13.9831 17.9878 14.0241C18.0715 14.1081 18.1185 14.2217 18.1185 14.3402C18.1185 14.4587 18.0715 14.5723 17.9878 14.6563L16.1429 16.4999C16.1018 16.5421 16.0526 16.5756 15.9983 16.5982C15.9439 16.6209 15.8855 16.6322 15.8266 16.6316Z" fill="url(#paint11_linear)"/>
        <path d="M14.9436 22.0046C14.8866 22.0058 14.8299 21.9947 14.7776 21.9719C14.7253 21.9492 14.6785 21.9154 14.6405 21.8729L10.1335 17.3822C10.0576 17.2969 10.0172 17.1857 10.0206 17.0716C10.0239 16.9575 10.0708 16.8489 10.1516 16.7682C10.2324 16.6874 10.341 16.6406 10.4553 16.6372C10.5695 16.6339 10.6807 16.6742 10.7661 16.7501L15.2599 21.2408C15.3436 21.3247 15.3906 21.4384 15.3906 21.5568C15.3906 21.6753 15.3436 21.789 15.2599 21.8729C15.2198 21.9164 15.1708 21.9507 15.1162 21.9735C15.0616 21.9962 15.0027 22.0068 14.9436 22.0046Z" fill="url(#paint12_linear)"/>
        <path d="M8.81599 23.6243C8.70341 23.6255 8.59476 23.583 8.51289 23.5058C8.42919 23.4219 8.3822 23.3082 8.3822 23.1897C8.3822 23.0713 8.42919 22.9576 8.51289 22.8737L10.3578 21.0168C10.3984 20.9712 10.4479 20.9344 10.5032 20.9086C10.5585 20.8828 10.6185 20.8685 10.6796 20.8667C10.7406 20.8649 10.8014 20.8756 10.8581 20.8981C10.9149 20.9206 10.9664 20.9545 11.0096 20.9977C11.0528 21.0408 11.0867 21.0923 11.1092 21.149C11.1317 21.2057 11.1424 21.2664 11.1406 21.3274C11.1388 21.3884 11.1246 21.4484 11.0987 21.5037C11.0729 21.559 11.036 21.6084 10.9904 21.649L9.13227 23.5058C9.04635 23.585 8.93285 23.6276 8.81599 23.6243Z" fill="url(#paint13_linear)"/>
        <path d="M7.94601 25.7447C7.66766 25.7469 7.39309 25.6803 7.14678 25.5507C6.90048 25.4211 6.6901 25.2326 6.5344 25.002C6.3787 24.7714 6.28253 24.5059 6.25445 24.2291C6.22638 23.9524 6.26727 23.673 6.3735 23.4159C6.47972 23.1588 6.64797 22.9319 6.86323 22.7556C7.0785 22.5792 7.3341 22.4588 7.60723 22.4051C7.88036 22.3515 8.16254 22.3662 8.4286 22.448C8.69466 22.5298 8.93633 22.6761 9.13206 22.8738C9.36529 23.1095 9.52381 23.4087 9.5878 23.7339C9.65179 24.0591 9.61841 24.396 9.49184 24.7024C9.36527 25.0087 9.15112 25.271 8.87618 25.4564C8.60123 25.6418 8.27769 25.7421 7.94601 25.7447ZM7.94601 23.2689C7.84051 23.2687 7.73602 23.2895 7.6387 23.3302C7.54138 23.3709 7.4532 23.4307 7.37935 23.506C7.30402 23.5785 7.24409 23.6654 7.20316 23.7616C7.16223 23.8577 7.14113 23.9612 7.14113 24.0657C7.14113 24.1702 7.16223 24.2736 7.20316 24.3698C7.24409 24.4659 7.30402 24.5529 7.37935 24.6254C7.53159 24.7718 7.73469 24.8536 7.94601 24.8536C8.15734 24.8536 8.36044 24.7718 8.51268 24.6254C8.60799 24.5114 8.66982 24.3733 8.6913 24.2263C8.71278 24.0793 8.69307 23.9293 8.63436 23.7929C8.57565 23.6564 8.48024 23.5389 8.35872 23.4534C8.23721 23.3679 8.09435 23.3177 7.94601 23.3084V23.2689Z" fill="url(#paint14_linear)"/>
        <defs>
        <linearGradient id="paint0_linear" x1="32.4355" y1="20.8549" x2="-8.32002" y2="9.16876" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0E7CAA"/>
        <stop offset="1" stop-color="#0CE2E2"/>
        </linearGradient>
        <linearGradient id="paint1_linear" x1="41.711" y1="9.90064" x2="4.15458" y2="-0.850426" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0E7CAA"/>
        <stop offset="1" stop-color="#0CE2E2"/>
        </linearGradient>
        <linearGradient id="paint2_linear" x1="27.355" y1="12.9575" x2="11.4578" y2="8.39986" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0E7CAA"/>
        <stop offset="1" stop-color="#0CE2E2"/>
        </linearGradient>
        <linearGradient id="paint3_linear" x1="42.3704" y1="29.3167" x2="0.273884" y2="17.2401" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0E7CAA"/>
        <stop offset="1" stop-color="#0CE2E2"/>
        </linearGradient>
        <linearGradient id="paint4_linear" x1="36.0132" y1="28.0058" x2="9.52566" y2="20.4132" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0E7CAA"/>
        <stop offset="1" stop-color="#0CE2E2"/>
        </linearGradient>
        <linearGradient id="paint5_linear" x1="34.5343" y1="27.0281" x2="9.63192" y2="19.8865" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0E7CAA"/>
        <stop offset="1" stop-color="#0CE2E2"/>
        </linearGradient>
        <linearGradient id="paint6_linear" x1="25.9908" y1="21.3308" x2="9.87158" y2="16.7164" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0E7CAA"/>
        <stop offset="1" stop-color="#0CE2E2"/>
        </linearGradient>
        <linearGradient id="paint7_linear" x1="24.7186" y1="11.6331" x2="-17.448" y2="-0.483158" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0E7CAA"/>
        <stop offset="1" stop-color="#0CE2E2"/>
        </linearGradient>
        <linearGradient id="paint8_linear" x1="17.8261" y1="9.83292" x2="-8.65613" y2="2.24277" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0E7CAA"/>
        <stop offset="1" stop-color="#0CE2E2"/>
        </linearGradient>
        <linearGradient id="paint9_linear" x1="17.9832" y1="10.4887" x2="-6.91524" y2="3.34193" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0E7CAA"/>
        <stop offset="1" stop-color="#0CE2E2"/>
        </linearGradient>
        <linearGradient id="paint10_linear" x1="19.0966" y1="14.4454" x2="2.98524" y2="9.82478" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0E7CAA"/>
        <stop offset="1" stop-color="#0CE2E2"/>
        </linearGradient>
        <linearGradient id="paint11_linear" x1="20.2823" y1="16.0979" x2="11.9158" y2="13.7" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0E7CAA"/>
        <stop offset="1" stop-color="#0CE2E2"/>
        </linearGradient>
        <linearGradient id="paint12_linear" x1="19.633" y1="20.9582" x2="3.22818" y2="16.2575" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0E7CAA"/>
        <stop offset="1" stop-color="#0CE2E2"/>
        </linearGradient>
        <linearGradient id="paint13_linear" x1="13.3201" y1="23.0867" x2="4.89284" y2="20.6724" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0E7CAA"/>
        <stop offset="1" stop-color="#0CE2E2"/>
        </linearGradient>
        <linearGradient id="paint14_linear" x1="12.2847" y1="25.0874" x2="1.97927" y2="22.1337" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0E7CAA"/>
        <stop offset="1" stop-color="#0CE2E2"/>
        </linearGradient>
        </defs>
        </svg>


    )
}